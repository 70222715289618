// @ts-check

import { db } from "firebaseConfig";
import { Page } from "./Page";
import { User } from "./User";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

export class Capsule {
  static COLLECTION_NAME = "posts";

  id = null;
  archived = false;
  artists = [];
  buys = [];
  comments = [];
  createdAt = "";
  createdOn = "";
  description = "";
  longDescription = "";
  events = [];
  pages = [];
  flags = [];
  gallery = "";
  imageURL = "";
  inventory = [];
  likes = [];
  nPages = 0;
  phones = [];
  userDisplayName = "";
  userEmail = "";
  userId = "";
  sharedBy = null;
  onHold = [];
  shareRequests = [];
  urgents = [];
  /**
   * @param {object} params
   * @param {string} params.id
   * @param {boolean} [params.archived]
   * @param {string[]} [params.artists]
   * @param {Object[]} [params.buys]
   * @param {Object[]} [params.comments]
   * @param {string} params.createdAt
   * @param {string} [params.createdOn]
   * @param {string} params.description
   * @param {string} [params.longDescription]
   * @param {Object[]} [params.events]
   * @param {Page[]} [params.pages]
   * @param {Object[]} [params.flags]
   * @param {string} [params.gallery]
   * @param {string} [params.imageURL]
   * @param {Object[]} [params.inventory]
   * @param {Object[]} [params.likes]
   * @param {number} params.nPages
   * @param {string[]} [params.phones]
   * @param {string} [params.userDisplayName]
   * @param {string} params.userEmail
   * @param {string} params.userId
   * @param {string} [params.sharedBy]
   * @param {Object[]} [params.onHold]
   * @param {Object[]} [params.shareRequests]
   * @param {Object[]} [params.urgents]
   */
  constructor({
    id,
    archived = false,
    artists = [],
    buys = [],
    comments = [],
    createdAt,
    createdOn = "",
    description,
    longDescription = "",
    events = [],
    pages = [],
    flags = [],
    gallery = "",
    imageURL = "",
    inventory = [],
    likes = [],
    nPages,
    phones = [],
    userDisplayName = "",
    userEmail,
    userId,
    sharedBy = null,
    onHold = [],
    shareRequests = [],
    urgents = [],
  }) {
    this.id = id;
    this.archived = archived;
    this.artists = artists;
    this.buys = buys;
    this.comments = comments;
    this.createdAt = createdAt;
    this.createdOn = createdOn;
    this.description = description;
    this.longDescription = longDescription;
    this.events = events;
    this.pages = pages;
    this.flags = flags;
    this.gallery = gallery;
    this.imageURL = imageURL;
    this.inventory = inventory;
    this.likes = likes;
    this.nPages = nPages;
    this.phones = phones;
    this.userDisplayName = userDisplayName;
    this.userEmail = userEmail;
    this.userId = userId;
    this.sharedBy = sharedBy;
    this.onHold = onHold;
    this.shareRequests = shareRequests;
    this.urgents = urgents;
  }

  getActionsStats() {
    const actions = {
      likes: this.likes.length,
      buys: this.buys.length,
      comments: this.comments.length,
      onHold: this.onHold.length,
      urgents: this.urgents.length,
    };
    return actions;
  }

  async getPages() {
    if (!this.pages) {
      this.pages = await Page.getPagesFromCapsule(this.id);
    }
    return this.pages;
  }

  static async getById(id) {
    const capsuleResponse = await getDoc(doc(db, Capsule.COLLECTION_NAME, id));

    // @ts-ignore
    return new Capsule({
      ...capsuleResponse.data(),
      id: capsuleResponse.id,
    });
  }

  // define type SharePerson
  /**
   * @typedef {Object} SharePerson
   * @property {string} [id]
   * @property {string} [name]
   * @property {string} [role]
   * @property {string} [profilePicUrl]
   * @property {string} [username]
   * @property {string} email
   */

  /**
   * Gets both registered and not registered people the capsule has been shared with
   * @param {string} capsuleId
   * @returns {Promise<SharePerson[]>}
   */
  static async getPeopleSharedWith(capsuleId) {
    const shares = await getDocs(
      query(collection(db, "sharedPosts"), where("post", "==", capsuleId))
    );

    const sharedWithEmails = [];
    const sharedWithIds = [];

    shares.docs.forEach((doc) => {
      if (doc.data().sharedWith) {
        sharedWithIds.push(doc.data().sharedWith);
      } else if (doc.data().sharedWithEmail) {
        sharedWithEmails.push(doc.data().sharedWithEmail);
      }
    });

    const sharesUsersDocs = await Promise.all(
      sharedWithIds.map((id) => getDoc(doc(db, "users", id)))
    );

    const users = sharesUsersDocs.map(
      (doc) => new User({ ...doc.data(), id: doc.id })
    );

    /**
     * @type {SharePerson[]}
     */
    let sharedWithPeole = users.map((user) => ({
      id: user.id,
      name: user.displayName,
      role: user.role,
      profilePicUrl: user.profilePicUrl || "",
      username: user.username,
      email: user.email,
    }));

    sharedWithEmails.forEach((email) => {
      sharedWithPeole.push({
        email: email,
      });
    });

    return sharedWithPeole;
  }
}
