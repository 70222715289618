/**
 * @typedef {import("./types").NavBarDropDownProps}  NavBarDropDownProps
 */

import React from "react";
import SearchInput from "../../atoms/inputs/SearchInput";
import { NavBarDropDown } from "./NavBarDropDown";
import { NotificationsDropdownMenu } from "./NotificationsDropdownMenu";
import { CustomButton } from "components/atoms/CustomButton";
import { useNavigate } from "react-router-dom";

/**
 * @param {object} props
 * @param {function():void} props.onSearch
 * @param {function(string):void} props.onSelectTab
 * @param {string} props.selectedTab
 * @param {number} props.notificationCounter
 * @param {object} props.notifications
 * @param {boolean} props.userIsLoggedIn
 * @param {Array<NavBarDropDownProps>} props.menuItems
 */
export function DesktopNabBarMenu({
  menuItems,
  onSelectTab,
  selectedTab,
  onSearch,
  notificationCounter,
  notifications,
  userIsLoggedIn,
}) {
  const navigate = useNavigate();

  return (
    <>
      <ul className="hidden lg:flex lg:flex-row">
        {userIsLoggedIn && (
          <li className="my-auto">
            <SearchInput onClick={onSearch}></SearchInput>
          </li>
        )}
        {menuItems.map((tab) => (
          <NavBarDropDown
            key={tab.title}
            tab={tab}
            isOpen={tab.title === selectedTab}
            onClick={onSelectTab}
          ></NavBarDropDown>
        ))}
        {userIsLoggedIn && (
          <li className="my-auto relative h-9 w-9 border rounded-full">
            <NotificationsDropdownMenu
              notificationCounter={notificationCounter}
              isOpen={"notifications" === selectedTab}
              onClick={onSelectTab}
              tabName={"notifications"}
              notifications={notifications}
            ></NotificationsDropdownMenu>
          </li>
        )}
      </ul>
      {!userIsLoggedIn && (
        <div className="hidden lg:flex lg:flex-row">
          <CustomButton
            variant="secondary"
            className="!px-4 mr-3"
            onClick={() => navigate("/login")}
          >
            Log In
          </CustomButton>
          <CustomButton className="!px-4" onClick={() => navigate("/register")}>
            Register
          </CustomButton>
        </div>
      )}
    </>
  );
}
