// @ts-check

/**
 * @typedef {import("../../utils/types").Capsule} CapsuleType
 */

import { useAuth } from "context/AuthContext";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CapsulePageCard } from "pages/PostDescription/CapsulePageCard";
import { Page } from "domainModels/Page";

import { LoginModal } from "./LoginModal";
import { PublicShareLink } from "domainModels/PublicShareLink";
import { Capsule } from "domainModels/Capsule";
import { LocalStorage } from "utils/LocalStorage";
import { SharedPost } from "domainModels/SharedPost";

export const PublicCapsule = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  /**
   * @type {[boolean, (boolean) => void]}
   */
  const [showLoginModal, setShowLoginModal] = useState(false);

  /**
   * @type {[Capsule | null, (capsule: Capsule | null) => void]}
   */
  const [post, setPost] = useState(null);
  /**
   * @type {[Page[], (pages: Page[]) => void]}
   */
  const [pages, setPages] = useState([]);

  const getData = async () => {
    dispatch({ type: "showLoading" });

    const publicCapsule = await PublicShareLink.getById(params.id);
    if (currentUser) {
      await SharedPost.create({
        postId: publicCapsule.capsuleId,
        fromUserId: publicCapsule.sharedByUserId,
        toUserId: currentUser?.uid,
      });
      navigate(`/post-s-grid/${publicCapsule.capsuleId}`);
      return;
    }
    const tempPost = await publicCapsule.getPost();
    setPost(tempPost);
    await getPages(tempPost.id);

    dispatch({ type: "hideLoading" });
  };

  /**
   *
   * @param {string} postId
   */
  const getPages = async (postId) => {
    const pages = await Page.getPagesFromCapsule(postId);
    // @ts-ignore
    setPages(pages);
  };
  const onInteraction = (page) => {
    if (!currentUser) {
      setShowLoginModal(true);
    }
  };

  useEffect(() => {
    getData();
    LocalStorage.savePendingPublicCapsule(params.id);
  }, [params.id]);

  return (
    <>
      <div className=" w-full h-full !py-10 !p-5  md:!p-10">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center align-middle">
          <h1 className="font-secondary">{post?.description}</h1>
          <p className="text-sm font-light mt-1 md:!mt-0">
            Uploaded on {moment(post?.createdAt).format("MMM DD,YYYY")}
          </p>
        </div>
        <div className="italic mt-3">{post?.longDescription}</div>

        <div className="grid mt-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 !gap-5">
          {post &&
            pages.map((page) => (
              <CapsulePageCard
                key={page.id}
                post={post}
                page={page}
                selectedUser={{ id: "all" }}
                setShowModal={() => onInteraction(page)}
                showCount={false}
                onFeedbackInteraction={onInteraction}
              ></CapsulePageCard>
            ))}
        </div>
      </div>
      {showLoginModal && (
        <LoginModal show={showLoginModal} setShow={setShowLoginModal} />
      )}
    </>
  );
};
