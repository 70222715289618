// @ts-check
import React, { useCallback, useMemo } from "react";
import Image from "react-bootstrap/Image";
import { CircularIcon } from "components/molecules/CircularIcon";
/**
 *
 * @param {object} props
 * @param {object} props.post
 * @param {object} props.page
 * @param {object} props.selectedUser
 * @param {boolean} [props.showCount]
 * @param {()=>void} [props.onFeedbackInteraction]
 * @param {()=>void} props.setShowModal
 * @param {Object} [props.filters]
 * @param {boolean} props.filters.likes
 * @param {boolean} props.filters.buys
 * @param {boolean} props.filters.urgents
 * @param {boolean} props.filters.comments
 * @param {boolean} props.filters.onHolds

 * @returns
 */
export const CapsulePageCard = ({
  post,
  page,
  setShowModal,
  showCount = true,
  onFeedbackInteraction,
  selectedUser,
  filters,
}) => {
  let alreadyUrgent = false;

  const urgentCount = (page) => {
    function checkUrgent(urgent) {
      return urgent.page === parseInt(page);
    }
    const filteredUrgents = post.urgents.filter(checkUrgent);
    return filteredUrgents.length;
  };

  const userUrgentCount = (page, user) => {
    function checkUrgent(urgent) {
      return urgent.page === parseInt(page) && urgent.id === user.id;
    }
    const filteredUrgents = post.urgents.filter(checkUrgent);
    return filteredUrgents.length;
  };

  if (selectedUser.id === "all") {
    if (urgentCount(page.page) > 0) {
      alreadyUrgent = true;
    }
  } else {
    if (userUrgentCount(page.page, selectedUser) > 0) {
      alreadyUrgent = true;
    }
  }

  const counters = useMemo(() => {
    const likeCount = (page) => {
      function checkLike(like) {
        return like.page === parseInt(page);
      }
      const filteredLikes = post.likes?.filter(checkLike);
      return filteredLikes.length;
    };

    const userLikeCount = (page, user) => {
      function checkLike(like) {
        return like.page === parseInt(page) && like.id === user.id;
      }
      const filteredLikes = post.likes?.filter(checkLike);
      return filteredLikes.length;
    };

    const commentCount = (page) => {
      function checkComment(comment) {
        return comment.page === parseInt(page) && comment.parentId === null;
      }
      const filteredComments = post.comments?.filter(checkComment);
      return filteredComments.length;
    };

    const userCommentCount = (page, user) => {
      function checkComment(comment) {
        return (
          comment.page === parseInt(page) &&
          comment.id === user.id &&
          comment.parentId === null
        );
      }
      const filteredComments = post.comments?.filter(checkComment);
      return filteredComments.length;
    };

    const onHoldCount = (page) => {
      const checkOnHold = (hold) => hold.page === parseInt(page);
      return post?.onHold?.filter(checkOnHold).length || 0;
    };

    const userOnHoldCount = (page, user) => {
      const checkOnHold = (hold) =>
        hold.page === parseInt(page) && hold.id === user.id;
      return post?.onHold?.filter(checkOnHold).length || 0;
    };

    const buyCount = (page) => {
      function checkBuy(buy) {
        return buy.page === parseInt(page);
      }
      const filteredBuys = post.buys?.filter(checkBuy);
      return filteredBuys.length;
    };

    const userBuyCount = (page, user) => {
      function checkBuy(buy) {
        return buy.page === parseInt(page) && buy.id === user.id;
      }
      const filteredBuys = post.buys?.filter(checkBuy);
      return filteredBuys.length;
    };

    if (!showCount) {
      return {
        likes: 0,
        holds: 0,
        buys: 0,
        comments: 0,
      };
    }

    const isAllUsers = selectedUser.id === "all";

    return {
      likes: isAllUsers
        ? likeCount(page.page)
        : userLikeCount(page.page, selectedUser),
      holds: isAllUsers
        ? onHoldCount(page.page)
        : userOnHoldCount(page.page, selectedUser),
      buys: isAllUsers
        ? buyCount(page.page)
        : userBuyCount(page.page, selectedUser),
      comments: isAllUsers
        ? commentCount(page.page)
        : userCommentCount(page.page, selectedUser),
    };
  }, [
    page.page,
    post?.buys,
    post?.comments,
    post?.likes,
    post?.onHold,
    selectedUser,
    showCount,
  ]);

  return (
    <div key={page.page} className="rounded-2xl overflow-hidden shadow">
      <div className="d-inline-flex position-relative justify-content-center">
        <Image
          src={page.imageURL}
          onClick={setShowModal}
          className={`border-none !p-0`}
        />
        <p className="text-xs position-absolute !top-3 !right-3 translate-middle-x  border-dark shadow-md  text-center bg-white rounded-full w-5 h-5 pt-0.5">
          {page.page}
        </p>
      </div>
      <div
        className={` flex justify-between bg-white !p-3 !pt-2  ${
          alreadyUrgent === true ? "border border-warning" : ""
        }`}
      >
        {(!filters || filters?.likes) && (
          <CircularIcon
            type={"heart"}
            count={counters.likes}
            onClick={onFeedbackInteraction ?? null}
          />
        )}
        {(!filters || filters.onHolds) && (
          <CircularIcon
            type={"hand"}
            count={counters.holds}
            onClick={onFeedbackInteraction ?? null}
          />
        )}
        {(!filters || filters.buys) && (
          <CircularIcon
            type={"money"}
            count={counters.buys}
            onClick={onFeedbackInteraction ?? null}
          />
        )}
        {(!filters || filters.comments) && (
          <CircularIcon
            type={"dialog"}
            count={counters.comments}
            onClick={onFeedbackInteraction ?? null}
          />
        )}
      </div>
    </div>
  );
};
