// @ts-check

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "firebaseConfig";

export class NetworkUser {
  id;
  createdAt;
  createdBy;
  email;
  isFav;
  name;
  status;
  sub;
  username;

  /**
   *
   * @param {object} params
   * @param {string} params.id
   * @param {string} params.createdAt
   * @param {string} params.createdBy
   * @param {string} params.email
   * @param {boolean} params.isFav
   * @param {string} params.name
   * @param {string} params.status
   * @param {string} params.sub
   * @param {string} params.username
   *
   */
  constructor({
    id,
    createdAt,
    createdBy,
    email,
    isFav,
    name,
    status,
    sub,
    username,
  }) {
    this.id = id;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.email = email;
    this.isFav = isFav;
    this.name = name;
    this.status = status;
    this.sub = sub;
    this.username = username;
  }

  static getUserNetwork = async (userId) => {
    console.log("getting network from user", userId);
    try {
      const networkDocs = await getDocs(
        collection(db, "users", userId, "network")
      );
      const network = networkDocs.docs.map((doc) => {
        // @ts-ignore
        return new NetworkUser({ ...doc.data(), id: doc.id });
      });

      const sortedNetwork = network.sort((a, b) => {
        // @ts-ignore
        if (a.isFav !== b.isFav) return b.isFav - a.isFav;
        return a.name.localeCompare(b.name);
      });
      console.log("network from user", sortedNetwork);
      return sortedNetwork;
    } catch (error) {
      console.error("Error getting network", error);
    }
  };
}
