import React, { useEffect, useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { toast } from 'react-toastify';
import { collection, documentId, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from '../firebaseConfig';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


const useExportPdf = ({ post,filteredPages, tableId, filter }) => {


    const [loading, setLoading] = useState(false)


    const filterDisplayName = async (array, page) => {
        const uniqueObjects = [];
        const seenIds = new Set();

        array.filter(obj => obj.page === parseInt(page.page))
            .map(obj => ({name: obj.displayName, id: obj.id}))
            .forEach(obj => {
                if (!seenIds.has(obj.id)) {
                    seenIds.add(obj.id)
                    uniqueObjects.push(obj)
                }
            })
        
        if(uniqueObjects.length === 0) return []

        const usersRef = query(collection(db, "users"), where(documentId(), 'in', uniqueObjects.map(obj => obj.id)))
        const usersDocs = await getDocs(usersRef)
        const usersData = usersDocs.docs.map(doc => ({uid: doc.id, username: doc.data().username}))

        return uniqueObjects.map(obj => {
            const user = usersData.find(user => user.uid === obj.id)
            return {...obj, username: user?.username}
        }).map(obj => ({
            text: obj.name,
            link: `${window.location.origin}/profile/${obj.username}`,
            style: 'link',
        }))
    }

    const exportToPDF = async () => {
        console.log("export called");
        
        setLoading(true)

        const columns = await Promise.all(
          filteredPages.map(async (page) => {
            var imgSrc = null;
            const response = await fetch(page.imageURL);
            const blobData = await response.blob();
            const reader = new FileReader();
            await new Promise((resolve) => {
              reader.onloadend = () => {
                imgSrc = reader.result;
                resolve();
              };
              reader.readAsDataURL(blobData);
            });

            const text = page.text;
            const likes = filter?.likes
              ? await filterDisplayName(post.likes, page)
              : [];
            const buys = filter?.buys
              ? await filterDisplayName(post.buys, page)
              : [];
            const comments = filter?.comments
              ? await filterDisplayName(post.comments, page)
              : [];

            return [
              page?.page,
              { image: imgSrc, fit: [80, 80] },
              text,
              [
                {
                  stack: [
                    { text: likes?.length > 0 && filter?.likes ? "Likes" : "" },
                    {
                      ul: likes,
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text:
                        comments?.length > 0 && filter?.comments
                          ? "Comments"
                          : "",
                    },
                    {
                      ul: comments,
                    },
                  ],
                },
                {
                  stack: [
                    { text: buys?.length > 0 && filter?.buys ? "Buys" : "" },
                    {
                      ul: buys,
                    },
                  ],
                },
              ],
            ];
          })
        ).catch((err) => {
          toast.error("Error while downloading PDF");
          setLoading(false);
        });

        const content = [
            ['#', 'Page', 'Details', 'Input'],
            ...columns
        ]

        const docDefinition = {
            content: [
                { text: `${post?.description} - Inputs`, style: 'header' },
                {
                    table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', '*', '*'],
                    body: content
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                link: {
                    color: 'blue',
                    decoration: 'underline'
                }
            }
        };

        pdfMake.createPdf(docDefinition).download(`${post.description} - Inputs.pdf`, (err) => {
            if(err) toast.error('Error while downloading PDF')
            setLoading(false)
        })

      };

    return {
      exportToPDF,
      loading,
    };

}

export default useExportPdf;