import React, { useState, useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Input from "../components/Input";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useAuth } from "../context/AuthContext";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

const Login = () => {
  const auth = getAuth();

  const { loading } = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const { login: firebaseLogin } = useAuth();

  const goToRegister = () => {
    navigate("/register");
  };

  useEffect(() => {
    setupRecaptcha();
  }, []);

  const setupRecaptcha = () => {
    const recaptcha = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );
    recaptcha.render().then(() => {
      recaptcha.verify().then(() => {
        setCaptchaVerified(true);
      });
    });
  };

  const login = () => {
    if (!captchaVerified) {
      toast.warning("Please wait while we verify you are not a robot");
      return;
    }
    dispatch({ type: "showLoading" });
    firebaseLogin(email, password)
      .then((user) => {
        toast.success("Login successful");
        navigate(location.state?.from || "/", { replace: true });
        dispatch({ type: "hideLoading" });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Login failed. Please try again.");
        dispatch({ type: "hideLoading" });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {" "}
      <div id="recaptcha-container"></div>
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        {" "}
        <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 pb-5 mb-5">
          <div className="pb-5">
            {" "}
            <Card className="mb-5">
              <span className="text-center">
                <Card.Header as="h4">Log in</Card.Header>
              </span>
              <Card.Body>
                <span className="text-center">
                  <Card.Title></Card.Title>
                </span>
                <div className="d-flex justify-content-center pb-2 pt-2 pb-3">
                  <div className="text-center">
                    <Input
                      label="Email"
                      type="email"
                      value={email}
                      setValue={setEmail}
                    />
                    <Input
                      label="Password"
                      type="password"
                      value={password}
                      setValue={setPassword}
                    />
                    <div className="d-grid mt-2">
                      <Button
                        id="sign-in-button"
                        variant="outline-dark"
                        onClick={() => login()}
                      >
                        {loading ? <Loader /> : "Log In"}
                      </Button>
                    </div>
                    <div className="d-grid mt-2">
                      <Button
                        variant="outline-secondary"
                        onClick={goToRegister}
                      >
                        Need to register?
                      </Button>
                    </div>
                    <div className="d-grid mt-2">
                      <Button
                        variant="outline-secondary"
                        onClick={() =>
                          navigate(
                            `/forgot-password${
                              email !== "" ? `?email=${email}` : ""
                            }`
                          )
                        }
                      >
                        Forgot Password?
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Login;
