/**
 * @param {Object} props
 * @param {"text"|"feedback"|"notes"} props.headerType
 * @param {boolean} [props.isMobile]
 * @returns
 */

export const TableHeader = ({ headerType, isMobile = false }) => {
  const headersContent = {
    text: {
      title: "Text (Editable)",
      description: "Change Availability and Price for All Users To See",
    },
    feedback: {
      title: "Feedback",
      description: "See reactions from Users and view their bios",
    },
    notes: {
      title: "Internal Notes (Private)",
      description:
        "For your eyes only. Add Feedback from non-romulus.art users here.",
    },
  };

  return (
    <>
      <p className={`${isMobile ? "font-bold" : ""}`}>
        {headersContent[headerType].title}
      </p>
      <p className="font-extralight italic text-sm">
        {headersContent[headerType].description}
      </p>
    </>
  );
};
