// @ts-check
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "firebaseConfig";
import moment from "moment";

export class SharedPost {
  static COLLECTION_NAME = "sharedPosts";
  /**
   * @param {object} params
   * @param {string} params.postId
   * @param {string} params.fromUserId
   * @param {string} [params.toUserId]
   * @param {string} [params.toEmail]
   */
  static async create({ postId, fromUserId, toUserId, toEmail }) {
    const isAlreadyShared = await SharedPost.isAlreadyShared({
      capsuleId: postId,
      sharedWith: toUserId,
      sharedWithEmail: toEmail,
    });
    if (isAlreadyShared) {
      return;
    }
    await addDoc(collection(db, SharedPost.COLLECTION_NAME), {
      post: postId,
      sharedWith: toUserId || null,
      sharedWithEmail: toEmail || null,
      sharedBy: fromUserId,
      isArchived: false,
      createdAt: moment().toISOString(),
    });
  }

  static async isAlreadyShared({ capsuleId, sharedWith, sharedWithEmail }) {
    let searchQuery;
    if (sharedWith) {
      searchQuery = where("sharedWith", "==", sharedWith);
    } else {
      searchQuery = where("sharedWithEmail", "==", sharedWithEmail);
    }

    const docs = await getDocs(
      query(
        collection(db, SharedPost.COLLECTION_NAME),
        where("post", "==", capsuleId),
        searchQuery
      )
    );
    const doc = docs.docs[0];
    return !!doc;
  }
}
