import { UserListItem } from "components/molecules/UserListItem";

/**
 * @typedef {Object} UserListItem
 * @property {string} title
 * @property {string} subtitle
 * @property {string} profilePictureUrl
 * @property {string} username
 */

/**
 *
 * @param {object} params
 * @param {UserListItem[]} params.userList
 * @param {function} params.onUserItemClick
 * @returns
 */
export const UserList = ({ userList, onUserItemClick }) => {
  return (
    <div>
      {userList.map((sharedWithPerson) => (
        <div className="!mt-5" key={sharedWithPerson.title}>
          <UserListItem
            onClick={() => onUserItemClick(sharedWithPerson.username)}
            title={sharedWithPerson.title}
            subtitle={sharedWithPerson.subtitle}
            profilePictureUrl={sharedWithPerson.profilePictureUrl}
            className={sharedWithPerson.username ? "hover:cursor-pointer" : ""}
          />
        </div>
      ))}
    </div>
  );
};
