// @ts-check
import React, { useState } from "react";
/**
 * @param {object} props
 * @param {function(string):void} props.onChange
 * @param {Array<string>} props.items
 * @param {string} props.selectedItem
 */
export const Tabs = ({ items, onChange,selectedItem}) => {
  
  /**
   * @param {string} item
   */
  const onSelect = (item) => {
    onChange(item);
  };
  return (
    <div className="bg-light-gray rounded p-1  flex flex-row sm:whitespace-nowrap">
      {items.map((item) => (
        <TabItem
          text={item}
          key={item}
          isSelected={item === selectedItem}
          onClick={onSelect}
        />
      ))}
    </div>
  );
};
/**
 * @param {object} props
 * @param {function(string):void} props.onClick
 * @param {string} props.text
 * @param {boolean} props.isSelected
 */
const TabItem = ({ text, onClick, isSelected }) => {
  return (
    <p
      className={`${
        isSelected ? "bg-white " : ""
      } rounded p-1 px-2 m-1 transition-all text-center hover:cursor-pointer`}
      onClick={() => onClick(text)}
    >
      {text}
    </p>
  );
};
