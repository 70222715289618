// @ts-check
import { ExternalLink } from "lucide-react";

/**
 * @typedef {import("../utils/types").FeedbackTypes} FeedbackTypes
 */

import { doc, getDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../firebaseConfig";
import UserProfileModal from "./UserProfileModal";
import { IconElement } from "./atoms/IconElement";

/**
 * @param {Object} props
 * @param {import("utils/types").Capsule} props.post
 * @param {number} props.pageNumber
 * @param {Object} props.userSelected
 * @param {Object} [props.filters]
 * @param {boolean} props.filters.likes
 * @param {boolean} props.filters.buys
 * @param {boolean} props.filters.urgents
 * @param {boolean} props.filters.comments
 * @param {boolean} props.filters.onHolds
 * @returns
 */
function InputFeedback({ post, pageNumber, userSelected, filters }) {
  const [selectedFeedback, setSelectedFeedback] = useState(
    /** @type {"likes"|"buys"|"comments"|"onHold"} */ ("likes")
  );

  const [showUserProfileModal, setShowUserProfileModal] = useState(false);

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async (id) => {
    getDoc(doc(db, "users", id, "public", "profile"))
      // @ts-ignore
      .then((response) => setUserProfile(response.data()))
      .then(() => {
        setShowUserProfileModal(true);
      })
      .catch((err) => console.log(err));
  };

  const filterArray = (array) => {
    const check = (obj) => obj.page === pageNumber;
    const checkWithUser = (obj) =>
      obj.page === pageNumber && obj.id === userSelected.id;

    return (
      array
        ?.filter(userSelected.id !== "all" ? checkWithUser : check)
        ?.sort((a, b) => a.displayName.localeCompare(b.displayName)) || []
    );
  };

  const feedbackIconDict = {
    likes: "heart",
    buys: "money",
    comments: "dialog",
    onHold: "hand",
  };

  const feedbackFiltersDict = {
    likes: filters?.likes,
    buys: filters?.buys,
    comments: filters?.comments,
    onHold: filters?.onHolds,
  };

  let uniqueUsers = {};

  const feedbackTypes = ["likes", "onHold", "buys", "comments"];
  return (
    <div className="w-full">
      {showUserProfileModal && (
        <UserProfileModal
          profile={userProfile}
          show={showUserProfileModal}
          setShow={setShowUserProfileModal}
        />
      )}
      <div className="p-2 flex w-full justify-between lg:justify-center">
        {feedbackTypes.map((type) => (
          <div
            className={`flex align-middle items-center mx-2 pb-1 border-b-[1.5px] border-b-transparent
              ${feedbackFiltersDict[type] ? "" : "hidden"}
                 ${type === selectedFeedback ? "!border-b-main" : ""}`}
          >
            <div
              // @ts-ignore
              onClick={() => setSelectedFeedback(type)}
              className="mr-[5px] cursor-pointer"
              style={{ cursor: "pointer" }}
            >
              <IconElement
                size={24}
                strokeWidth="1"
                type={feedbackIconDict[type]}
              />
            </div>
            <span className="text-xs">{filterArray(post[type]).length}</span>
          </div>
        ))}
      </div>

      <div className="flex mt-2 w-full">
        {filterArray(post[selectedFeedback]).length === 0 ||
        !feedbackFiltersDict[selectedFeedback] ? (
          <div className="text-xs text-aux-gray flex justify-center w-full">
            There are no reactions yet.
          </div>
        ) : (
          <>
            {filterArray(post[selectedFeedback])
              .filter((obj) => {
                if (uniqueUsers[obj.id]) return false;
                else {
                  uniqueUsers[obj.id] = true;
                  return true;
                }
              })
              .map((user) => (
                <div
                  className="py-1 px-2 font-light border border-aux-gray mr-2 cursor-pointer flex items-center align-middle justify-center"
                  onClick={() => getUserProfile(user.id)}
                >
                  {user.displayName}{" "}
                  <ExternalLink className="ml-2" size={15} strokeWidth={1.5} />
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default InputFeedback;
