import React from "react";
import Select, { components } from "react-select";
import { ChevronDown } from "lucide-react";

/**
 * @typedef {Object} Option
 * @property {string} value
 * @property {string} label
 * @property {string} subtitle
 * @property {import("react").ReactElement} icon
 */

/**
 *
 * @param {object} params
 * @param {Option[]} params.options
 * @param {Option} params.value
 * @param {Function} params.onChange
 */
export const CustomSelect = ({ options, value, onChange }) => {
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center p-1">
          {props.data.icon}
          <div className="ml-3">
            <p className="font-regular mb-.5">{props.data.label}</p>
            <p className="text-tertiary-gray text-sm">{props.data.subtitle}</p>
          </div>
        </div>
      </components.Option>
    );
  };

  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div className="flex items-center p-1">
          {props.data.icon}
          <div className="ml-3">
            <div className="flex items-center">
              <p className="font-regular mr-1">{props.data.label}</p>
              <ChevronDown size={16} />
            </div>
            <p className="text-tertiary-gray text-sm mt-.5">
              {props.data.subtitle}
            </p>
          </div>
        </div>
      </components.SingleValue>
    );
  };

  return (
    <Select
      id="custom-select"
      components={{
        Option,
        SingleValue,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      options={options}
      value={value}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        border: "0px",
        colors: {
          ...theme.colors,
          primary: "black",
        },
      })}
    />
  );
};
