// @ts-check

/**
 * @typedef {import("../utils/types").RouteButton} RouteButton
 * @typedef {import("../utils/types").Capsule} Capsule
 * @typedef {import("../utils/types").Order} Order
 * @typedef {import("../utils/types").DashboardTableData} DashboardTableData
 * @typedef {import("../utils/types").SharedPost} SharedPost
 * @typedef {import("../utils/types").CollectionSharedPost} CollectionSharedPost
 * @typedef {import("../utils/types").SharedOrder} SharedOrder
 */

import React, { useEffect, useState } from "react";
import { DashboardTable } from "../components/organisms/DashboardTable";
import { DashboardCard } from "../components/atoms/DashboardCard";
import { CustomButton } from "../components/atoms/CustomButton";
import { CapsuleCarousel } from "../components/organisms/CapsuleCarousel";
import { CapsuleCarouselInfo } from "../components/organisms/CapsuleCarouselInfo";
import { FeedbackOverview } from "../components/organisms/FeedbackOverview";

import { useAuth } from "../context/AuthContext";
import {
  collection,
  getDocs,
  query,
  getDoc,
  where,
  doc,
} from "@firebase/firestore";
import { db } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

/**
 * @type {Capsule[]}
 */
const exampleCarouselCapsules = [
  {
    id: "JtK28tkc0uBNTtlxZ8AK",
    createdAt: "2021-10-01T00:00:00.000Z",
    description:
      "Sean Kelly - Kehinde Wiley_Marcela Santos de Melo Study, 2008",
    nPages: 5,
    userEmail: "mail",
    userId: "1",
    imageURL: "https://placekitten.com/200/300",
  },
  {
    id: "JtK28tkc0uBNTtlxZ8AK",
    createdAt: "2021-10-01T00:00:00.000Z",
    description:
      "Art Title 4 - This is a Long Title, So Long That It Might Overflow, But It's Okay. Some Titles Are Just Long.",
    nPages: 5,
    userEmail: "mail",
    userId: "1",
    imageURL: "https://placekitten.com/200/300",
  },
  {
    id: "JtK28tkc0uBNTtlxZ8AK",
    createdAt: "2021-10-01T00:00:00.000Z",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi leo. In sit amet lectus quis ante aliquam rutrum. Curabitur porttitor nisi id semper tincidunt. Fusce rhoncus nibh sapien, ut faucibus est finibus ut. Vestibulum non magna at velit gravida blandit. Nullam scelerisque vulputate diam, nec molestie nulla molestie vel. Integer rutrum cursus vestibulum. Proin at eros iaculis, egestas ipsum ut, auctor erat. Vivamus et neque mi. Morbi commodo ornare tincidunt. Integer massa urna, congue ut pharetra eu, porttitor vitae ligula. Suspendisse pretium tristique lectus, et dapibus erat lobortis in. Aenean tortor tellus, tempor non consequat nec, porta vel libero. Sed ultrices nunc augue, vitae iaculis diam ultricies eget. Nunc ac pellentesque elit, eget tincidunt neque.",
    nPages: 5,
    userEmail: "mail",
    userId: "1",
    imageURL: "https://placekitten.com/300/300",
  },
];

/**
 * @type {RouteButton[]}
 */
const incomingCapsulesRouteButtons = [
  {
    text: "Make Connections to Receive Capsules",
    path: "/connections/",
    variant: "secondary",
  },
];

/**
 * @type {RouteButton[]}
 */
const pendingPurchasesRouteButtons = [
  {
    text: "Make Connections to Receive Capsules",
    path: "/connections/",
    variant: "secondary",
  },
];

const DashboardNew = () => {
  const {
    currentUserRealTime: currentUser,
    isConnoisseur,
    isCurious,
    isVendor,
  } = useAuth();

  const navigate = useNavigate();

  /** @type {DashboardTableData[]} */
  const dashboardTableDataInitialState = [];

  /** @type {Capsule[]} */
  const incomingCapsulesInitialState = [];

  /** @type {[DashboardTableData[], function(DashboardTableData[]): void]} */
  const [pendingSales, setPendingSales] = useState(
    dashboardTableDataInitialState
  );

  /** @type {[DashboardTableData[], function(DashboardTableData[]): void]} */
  const [pendingPurchasesSeller, setPendingPurchasesSeller] = useState(
    dashboardTableDataInitialState
  );

  /** @type {[DashboardTableData[], function(DashboardTableData[]): void]} */
  const [pendingPurchasesVendor, setPendingPurchasesVendor] = useState(
    dashboardTableDataInitialState
  );

  /** @type {[Capsule[], function(Capsule[]): void]} */
  const [incomingCapsules, setIncomingCapsules] = useState(
    incomingCapsulesInitialState
  );

  /**
   *
   * @param {Order} order
   * @returns boolean
   */
  const filterOrders = (order) => {
    if (isConnoisseur()) return !order?.archivedByConnoisseur;
    if (isCurious()) return !order?.archivedByCurious;
    return true;
  };

  useEffect(() => {
    if (currentUser) {
      getOrders();
      getSharedPosts();
      getSharedOrders();
    }
  }, [currentUser]);

  const getOrders = () => {
    getDocs(collection(db, "orders")).then((response) => {
      /**
       * @type {Order[]}
       */
      // @ts-ignore
      const orders = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
        };
      });

      /**
       * @type {DashboardTableData[]}
       */
      const pendingSales = orders
        .filter((item) => item?.shareInfo?.sharedById === currentUser?.uid)
        ?.filter(filterOrders)
        ?.sort((a, b) => {
          const dateA = new Date(a.createdOn).getTime();
          const dateB = new Date(b.createdOn).getTime();
          return dateB - dateA;
        })
        .map((order) => ({
          artImageUrl: order?.imageURL,
          title: order?.postDescription,
          actor: order?.userDisplayName,
          status: order?.status,
          path: `/order-o-status/${order.id}`,
        }));

      setPendingSales(pendingSales.slice(0, 3));

      /**
       * @type {DashboardTableData[]}
       */
      const pendingPurchases = orders
        ?.filter((item) => item?.userId === currentUser?.uid)
        ?.filter(filterOrders)
        ?.sort((a, b) => {
          const dateA = new Date(a.createdOn).getTime();
          const dateB = new Date(b.createdOn).getTime();
          return dateB - dateA;
        })
        ?.map((order) => ({
          title: order?.postDescription,
          artImageUrl: order?.imageURL,
          actor: order?.shareInfo?.sharedByDisplayName,
          status: order?.status,
          path: `/order-s-status/${order?.id}`,
        }));

      setPendingPurchasesSeller(pendingPurchases.slice(0, 3));
    });
  };

  const getSharedPosts = async () => {
    const response = await getDocs(
      query(
        collection(db, "sharedPosts"),
        where("sharedWith", "==", currentUser?.uid)
      )
    );
    const shares = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    /**
     * @type {Capsule[]}
     */
    // @ts-ignore
    const sharedCapsules = await Promise.all(
      shares
        .sort((a, b) => {
          // @ts-ignore
          const momentA = moment(a.createdAt, "YYYY-MM-DD hh:mm:ss a");
          // @ts-ignore
          const momentB = moment(b.createdAt, "YYYY-MM-DD hh:mm:ss a");

          return momentB.utc().valueOf() - momentA.utc().valueOf();
        })
        .map(async (/** @type {CollectionSharedPost}  */ share) => {
          // @ts-ignore
          const capsuleDoc = await getDoc(doc(db, "posts", share.post));
          // @ts-ignore
          const userDoc = await getDoc(doc(db, "users", share.sharedBy));
          return {
            ...capsuleDoc.data(),
            id: capsuleDoc.id,
            sharedBy: userDoc.data(),
          };
        })
    );
    setIncomingCapsules(sharedCapsules.slice(0, 6));
  };

  const getSharedOrders = async () => {
    const response = await getDocs(
      query(
        collection(db, "sharedOrders"),
        where("sharedWith", "==", currentUser?.uid)
      )
    );
    const shares = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    /**
     * @type {SharedOrder[]}
     */
    // @ts-ignore
    const sharedOrders = await Promise.all(
      shares.map(async (share) => {
        // @ts-ignore
        const orderDoc = await getDoc(doc(db, "orders", share.order));
        // @ts-ignore
        const user = await getDoc(doc(db, "users", share.sharedBy));
        return {
          ...share,
          order: { ...orderDoc.data(), id: orderDoc.id },
          sharedBy: user.data(),
        };
      })
    );

    setPendingPurchasesVendor(
      sharedOrders
        .sort((a, b) => {
          const momentA = moment(a.createdAt, "YYYY-MM-DD hh:mm:ss a");
          const momentB = moment(b.createdAt, "YYYY-MM-DD hh:mm:ss a");

          return momentB.utc().valueOf() - momentA.utc().valueOf();
        })
        .map((so) => so.order)
        .map((order) => ({
          title: order?.postDescription,
          artImageUrl: order?.imageURL,
          actor: order?.userDisplayName,
          status: order?.status,
          path: `/order-s-status/${order?.id}`,
        }))
    );
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row px-4 mt-4">
        <h1 className="text-center w-full sm:w-auto sm:!text-left font-secondary mr-auto normal text-xl py-1">
          Welcome back, Rachel!
        </h1>
        <div className="mt-auto grid grid-cols-2 gap-1 sm:gap-4  sm:p-0 sm:grid-cols-2">
          <CustomButton variant="secondary" className="w-full h-10  text-base">
            <a href="/connections">Invite user</a>
          </CustomButton>
          <CustomButton className="w-full h-10 text-base">
            <a href="/addPost">Upload Capsule</a>
          </CustomButton>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-4 p-0 md:!p-6 mt-4 md:!mt-0">
        <p className="subheading lg:col-span-2 px-3 md:!px-0">I Want to Sell</p>
        <DashboardCard
          title={"Outgoing Capsules"}
          description={
            "What is a capsule? It’s a preview of an art exhibition, a singular artwork offering, and more."
          }
          callToActionText={"See All"}
          onCallToAction={() => navigate("/profile-grid/")}
          type={"primary"}
        >
          <div className="grid grid-cols-9">
            <div className="col-span-9 md:col-span-4">
              <CapsuleCarousel
                capsuleList={exampleCarouselCapsules}
                onClickPath="''"
                variant="primary"
              />
            </div>
            <div className="col-span-9 md:col-span-5 px-3 md:flex md:align-middle md:h-80 ">
              <FeedbackOverview />
            </div>
          </div>
        </DashboardCard>
        <DashboardCard
          title={"Pending Sales"}
          description={
            "These are the Capsules you uploaded that have a buyer interested! Keep in touch with them to finish the sale process."
          }
          callToActionText={"See All"}
          onCallToAction={() => navigate("/acquisition-status-seller/")}
          type={"primary"}
        >
          <DashboardTable
            headers={["Art", "Title", "Buyer", "Status"]}
            data={pendingSales}
            className="mt-4"
          />
        </DashboardCard>
        <p className="subheading lg:col-span-2 px-3 md:!px-0">I Want to Buy</p>
        <DashboardCard
          title={"Incoming Capsules"}
          description={
            "What is a Capsule? It’s a preview of an art exhibition, a singular artwork offering, and more."
          }
          callToActionText={"See All"}
          onCallToAction={() => navigate("/shares/")}
          type={"secondary"}
        >
          {incomingCapsules.length > 0 ? (
            <div className="flex justify-center items-center">
              <div className="w-full px-4 lg:!px-0">
                <CapsuleCarouselInfo
                  capsuleList={incomingCapsules}
                  onClickPath={"/post-s-grid/"}
                  variant="secondary"
                />
              </div>
            </div>
          ) : (
            <EmptyDashboardCardContent
              text="What is a Capsule? It’s a preview of an art exhibition, a singular artwork offering, and more. This needs to be shared by someone on your network. Find new galleries, artists or art advisors at romulus.art!"
              routeButtons={incomingCapsulesRouteButtons}
              variant="secondary"
            />
          )}
        </DashboardCard>

        <DashboardCard
          title={"Pending Purchases"}
          type={"secondary"}
          description={
            "These are the Capsules where you have started an offering. It will display starting from most recent."
          }
          callToActionText={"See All"}
          onCallToAction={() => navigate("/acquisition-status-buyer/")}
        >
          {pendingPurchasesSeller.length > 0 ||
          pendingPurchasesVendor.length > 0 ? (
            <DashboardTable
              variant={"secondary"}
              headers={["Art", "Title", "Seller", "Status"]}
              data={
                isVendor() ? pendingPurchasesVendor : pendingPurchasesSeller
              }
              className="mt-4"
            />
          ) : (
            <EmptyDashboardCardContent
              text={
                "You don’t have any purchases in progress yet. Check the Capsules your contact have sent you or connect with more people to have more content."
              }
              routeButtons={pendingPurchasesRouteButtons}
              variant={"secondary"}
            />
          )}
        </DashboardCard>
      </div>
    </div>
  );
};

/**
 * @param {object} props
 * @param {string} props.text
 * @param {RouteButton[]} props.routeButtons
 * @param {"primary"|"secondary"} props.variant
 */
const EmptyDashboardCardContent = ({
  text,
  routeButtons,
  variant = "primary",
}) => {
  return (
    <div
      className={`text-base flex flex-col items-center justify-center h-fit p-8 xl:p-28 md:p-16  ${
        variant === "primary" ? "text-white " : "text-main"
      }`}
    >
      <p className="text-center font-light">{text}</p>
      <div className="flex flex-col mt-4 w-full">
        {routeButtons.map((button) => (
          <CustomButton
            key={button.text}
            className={`w-full h-10 mb-3 text-main ${
              variant === "primary" ? "!bg-white " : "!bg-transparent"
            }`}
            variant={button.variant ?? "primary"}
          >
            <a href={button.path}>{button.text}</a>
          </CustomButton>
        ))}
      </div>
    </div>
  );
};

export default DashboardNew;
