
export class LocalStorage {
  static savePendingPublicCapsule(publicShareLinkId) {
    if(!publicShareLinkId){
      localStorage.removeItem("PendingPublicCapsule");
      return
    }
    localStorage.setItem("PendingPublicCapsule", publicShareLinkId);
  }

  static getPendingPublicCapsule() {
    return localStorage.getItem("PendingPublicCapsule");
  }
}