// @ts-check
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "firebaseConfig";

export class Invitation {
  static COLLECTION_NAME = "invitations";

  invitedEmail;
  invitedBy;
  /**
   * @param {object} props
   * @param {string} props.invitedEmail
   * @param {Array<string>} props.invitedBy Ids of users
   */
  constructor({ invitedEmail, invitedBy }) {
    this.invitedEmail = invitedEmail;
    this.invitedBy = invitedBy;
  }

  static async create({ fromUserId, toEmail }) {
    const invitationDoc = await getDoc(
      doc(db, Invitation.COLLECTION_NAME, toEmail)
    );
    if (!invitationDoc.exists()) {
      setDoc(doc(db, Invitation.COLLECTION_NAME, toEmail), {
        invitedBy: [fromUserId],
      });
      return;
    }

    // Potential user has already been invited by someone
    const invitationData = invitationDoc.data();
    const userAlreadyInvitedBy = new Set([...invitationData.invitedBy]);
    const invitationAlreadyCreated = userAlreadyInvitedBy.has(fromUserId);
    if (invitationAlreadyCreated) {
      return;
    }

    await setDoc(
      doc(db, Invitation.COLLECTION_NAME, toEmail),
      {
        invitedBy: [...invitationData.invitedBy, fromUserId],
      },
      { merge: true }
    );
  }

  static async getByEmail({ email }) {
    const invitationDoc = await getDoc(
      doc(db, Invitation.COLLECTION_NAME, email)
    );
    return invitationDoc.data();
  }

  static async delete({ fromUserId, toEmail }) {
    const invitationDoc = await getDoc(
      doc(db, Invitation.COLLECTION_NAME, toEmail)
    );
    if (!invitationDoc.exists()) {
      return;
    }
    const invitationData = invitationDoc.data();

    await setDoc(
      doc(db, Invitation.COLLECTION_NAME, toEmail),
      {
        invitedBy: [
          ...invitationData.invitedBy.filter((id) => id !== fromUserId),
        ],
      },
      { merge: true }
    );
  }
}
