/**
 * @typedef {import("./types").NavBarDropDownProps}  NavBarDropDownProps
 */

import React, { useMemo } from "react";
import SearchInput from "../../atoms/inputs/SearchInput";
import { NavBarDropDown } from "./NavBarDropDown";
import { CustomButton } from "../../atoms/CustomButton";
import { sellerTab } from "../../organisms/NavBar/constants";
import { useNavigate } from "react-router-dom";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {function():void} props.onClick
 * @param {function():void} props.onSearch
 * @param {boolean} props.userIsLoggedIn
 * @param {Array<NavBarDropDownProps>} props.menuItems
 */
export function MobileNabBarMenu({
  isOpen,
  onClick,
  onSearch,
  menuItems,
  userIsLoggedIn,
}) {
  const containsSellerMenuItem = useMemo(() => {
    for (const item of menuItems)
      if (item.title === sellerTab.title) return true;
    return false;
  }, [menuItems]);

  const navigate = useNavigate();

  return (
    <>
      {!userIsLoggedIn ? (
        <CustomButton
          size="small"
          className="lg:hidden"
          onClick={() => navigate("/register")}
        >
          Register
        </CustomButton>
      ) : null}
      <div
        className={`top-16 fixed w-full h-full duration-500 ${
          isOpen
            ? "lg:opacity-0 right-0 bg-black opacity-20 "
            : "hidden bottom-0 right-[-100%]"
        }`}
        onClick={onClick}
      ></div>
      <ul
        className={`flex flex-col w-full px-4 bg-white duration-500 fixed h-full overflow-y-scroll lg:hidden pt-3 pb-16 top-16 
              ${
                isOpen
                  ? "right-0 sm:w-96"
                  : "lg:w-96 duration-500 opacity-0 right-[-100%]"
              }
            `}
      >
        {userIsLoggedIn && <SearchInput onClick={onSearch} />}

        {menuItems.map((item) => (
          <NavBarDropDown
            key={item.title}
            tab={item}
            isOpen={true}
          ></NavBarDropDown>
        ))}
        {userIsLoggedIn && (
          <li className="my-4">
            <CustomButton variant="secondary" className="w-full h-10 text-base">
              <a href="/connections">Invite Users</a>
            </CustomButton>
            {containsSellerMenuItem && (
              <CustomButton className="w-full mt-2 h-10 text-base">
                <a href="/addPost">Upload Capsule</a>
              </CustomButton>
            )}
          </li>
        )}
      </ul>
    </>
  );
}
