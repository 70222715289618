// @ts-check

import React, { useState } from "react";
import { Carousel, Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import { truncateText } from "../../utils/text";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

/**
 * @typedef {import("../../utils/types").Capsule} Capsule
 */
/**
 *  @param {object} props
 *  @param {Capsule[]} props.capsuleList
 *  @param {string} props.onClickPath
 *  @param {"primary"|"secondary"} props.variant
 *  */
export const CapsuleCarouselInfo = ({
  capsuleList,
  onClickPath,
  variant = "primary",
}) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  /**
   * Sets the new index for the carousel in a cyclic manner (after the last element, it goes back to the first)
   * @param {string} direction prev or next
   */
  const setNewIndex = (direction) => {
    if (direction === "prev") {
      if (currentIndex === 0) setCurrentIndex(capsuleList.length - 1);
      else setCurrentIndex((prev) => prev - 1);
    } else {
      if (currentIndex === capsuleList.length - 1) setCurrentIndex(0);
      else setCurrentIndex((prev) => prev + 1);
    }
  };

  /** Swipe on mobile doesn't automatically trigger index change,
   * so we need to handle it manually to avoid a disparity between the index and the displayed item
   */

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) setNewIndex("next");
    if (isRightSwipe) setNewIndex("prev");
  };

  return (
    <div
      className={`flex justify-center w-full 2xl:!px-24 px-1 border border-light-gray md:!relative md:!border-transparent  ${
        variant === "primary" ? "text-white" : "text-black"
      }`}
    >
      <div className="flex align-items-center">
        <HiChevronLeft
          onClick={() => setNewIndex("prev")}
          className={`cursor-pointer  md:mr-5`}
          size={15}
        />
      </div>
      <Carousel
        activeIndex={currentIndex}
        indicators={false}
        controls={false}
        className={`w-full rounded-none md:!border md:!border-light-gray`}
        touch={true}
        data-touch={true}
        data-bs-ride="carousel" // Required for swipe to work
        interval={100000} // Required to stop the carousel from auto-sliding (which is automatically activated by data-bs-ride)
        data-bs-interval={100000} // Idem
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {capsuleList.map((capsule) => (
          <Carousel.Item className="card text-center ">
            <div>
              {!capsule.imageURL ? (
                <div className="flex align-middle justify-center w-full">
                  Failed to load preview pages
                </div>
              ) : (
                <div className="grid grid-cols-2">
                  <Image
                    fluid
                    className="h-80 w-full object-cover cursor-pointer col-span-2 md:col-span-1"
                    src={capsule?.imageURL}
                    onClick={() => navigate(onClickPath + capsule.id)}
                  />

                  <div className="h-full col-span-2 md:col-span-1 flex flex-col justify-center lg:p-2">
                    <div className="text-sm md:mt-3 overflow-auto">
                      <p className="subheading">
                        {truncateText(capsule.description, 40)}
                      </p>
                      {capsule.sharedBy && (
                        <p className="text-sm -mt-1 overflow-auto italic">
                          Sent by {capsule.sharedBy.displayName}
                        </p>
                      )}

                      <p className="text-sm mt-3">
                        {truncateText(capsule?.longDescription, 100)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="flex align-items-center md:ml-5">
        <HiChevronRight
          onClick={() => setNewIndex("next")}
          className={`cursor-pointer `}
          size={15}
        />
      </div>
    </div>
  );
};
