import { Lock, LockOpen } from "lucide-react";

export const ACCESS_OPTIONS = [
  {
    value: "restricted",
    label: "Restricted",
    subtitle: "Only people with access can open with the link",
    icon: <Lock size={24} />,
  },
  {
    value: "public",
    label: "Anyone with the link",
    subtitle: "Anyone on the internet with the link can view",
    icon: <LockOpen size={24} />,
  },
];
