// @ts-check

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "firebaseConfig";
import { Capsule } from "./Capsule";

/*
  When a user is created (cloud function)
  -[] check if there is a sharedPost to the email, and change the email for the userID
  -[] check if there is an Invitation document and delete it 
  When a user is created (on client)
  - []check if the registration process started from a public share link and invite the owner fo the link 
  then add the Capsule to my previews

  */

export class PublicShareLink {
  capsuleId;
  sharedByUserId;
  /**
   * @type {"public"|"restricted"}
   */
  type = "public";
  id;

  static COLLECTION_NAME = "publicShareLink";

  /**
   * @param {object} params
   * @param {string} [params.id]
   * @param {string} params.capsuleId
   * @param {string} params.sharedByUserId
   * @param {"public"|"restricted"} [params.type]
   */
  constructor({ id, capsuleId, sharedByUserId, type = "public" }) {
    this.capsuleId = capsuleId;
    this.sharedByUserId = sharedByUserId;
    this.id = id;
    this.type = type;
  }
  /**
   * @param {PublicShareLink} shareLinkData
   */
  static async generateNew(shareLinkData) {
    const generated = await addDoc(
      collection(db, PublicShareLink.COLLECTION_NAME),
      {
        capsuleId: shareLinkData.capsuleId,
        sharedByUserId: shareLinkData.sharedByUserId,
        type: shareLinkData.type,
      }
    );
    shareLinkData.id = generated.id;
    return shareLinkData;
  }

  /**
   * @param {object} params
   * @param {string} params.capsuleId
   * @param {string} params.sharedByUserId
   */
  static async get({ capsuleId, sharedByUserId }) {
    const docs = await getDocs(
      query(
        collection(db, PublicShareLink.COLLECTION_NAME),
        where("capsuleId", "==", capsuleId),
        where("sharedByUserId", "==", sharedByUserId)
      )
    );
    const doc = docs.docs[0];
    if (!doc) return;
    // @ts-ignore
    return new PublicShareLink({ ...doc.data(), id: doc.id });
  }

  static async getOrCreate({ capsuleId, sharedByUserId }) {
    let publicShareLink;
    publicShareLink = await PublicShareLink.get({ capsuleId, sharedByUserId });
    if (publicShareLink) return publicShareLink;
    publicShareLink = new PublicShareLink({ capsuleId, sharedByUserId });
    publicShareLink = await PublicShareLink.generateNew(publicShareLink);
    return publicShareLink;
  }

  static async getById(id) {
    const publicCapsuleResponse = await getDoc(
      doc(db, PublicShareLink.COLLECTION_NAME, id)
    );

    // @ts-ignore
    return new PublicShareLink({
      ...publicCapsuleResponse.data(),
      id: publicCapsuleResponse.id,
    });
  }

  async getPost() {
    return await Capsule.getById(this.capsuleId);
  }

  getFullURL() {
    if (!this.id) {
      throw new Error("Share link doesn't have an id");
    }
    return `${window.location.origin}/public-capsule/${this.id}`;
  }

  toJson() {
    return { ...this };
  }

  /**
   *
   * @param {"public"|"restricted"} type
   */
  async updateType(type) {
    const updatedDoc = {
      capsuleId: this.capsuleId,
      sharedByUserId: this.sharedByUserId,
      type,
    };

    await setDoc(doc(db, PublicShareLink.COLLECTION_NAME, this.id), updatedDoc);
    this.type = type;
  }
}
