import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { CustomButton } from "components/atoms/CustomButton";
import { CircularIcon } from "components/molecules/CircularIcon";
// @ts-check
export const LoginModal = ({ show, setShow }) => {
  const navigate = useNavigate();
  return (
    <Modal size="lg" show={show !== null} onHide={() => setShow(false)}>
      <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
        <CircularIcon
          type={"x"}
          size={20}
          strokeWidth="1"
          className="!ml-auto !mr-0  hover:cursor-pointer stroke-1"
          onClick={() => setShow(false)}
        />
        <div className="flex flex-col justify-center items-center max-w-96 m-auto !my-40">
          <img className="mb-2" src="/logo.png" width="350" alt="Logo" />
          <p className="justify-center text-center mb-10">
            To interact with any page of this Capsule, you need to have an
            account.
          </p>
          <CustomButton
            className="mb-7 w-full"
            onClick={() => navigate("/register")}
          >
            Register
          </CustomButton>

          <div className="flex flex-row w-full items-center mb-7">
            <hr className="grow" />
            <p className="grow-0 !mx-8">or</p>
            <hr className="grow" />
          </div>

          <p className="text-center">
            Do you already have an account?{" "}
            <span
              className="text-brand-blue-secondary hover:cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Log in here.
            </span>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
