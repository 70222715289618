// @ts-check

import React from "react";

/**
 *
 * @param {object} props
 * @param {string} props.src
 * @param {string} props.name
 * @returns
 */
export const Avatar = ({ src, name }) => {
  const baseClasses = "h-10 w-10 !border-[1.5px] !border-main rounded-full";
  return src ? (
    <img className={`${baseClasses}`} alt={"Avatar of " + name} src={src} />
  ) : (
    <EmptyAvatar name={name} className={baseClasses} />
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.className
 * @returns
 */
const EmptyAvatar = ({ name, className }) => {
  if (!name) return null;
  const initial = name.charAt(0).toUpperCase();
  return (
    <span
      className={`flex justify-center items-center font-bold text-lg bg-[#D9D9D9]  ${className}`}
    >
      {initial}
    </span>
  );
};
