
import { IconElement } from "components/atoms/IconElement";
import React from "react";
/**
 * @param {object} props
 * @param {number} props.count
 * @param {string} props.title
 * @param {"heart"|"money"|"hand"|"dialog"} props.icon
 */
export const FeedbackOverviewListItem = ({ count, title, icon }) => {


  return (
    <div className=" text-white text-base flex flex-row">
      <p className="flex-none w-8 font-light text-right">{count}</p>
      <i className="flex-none my-auto font-light ml-5 mr-2"><IconElement type={icon}></IconElement></i>
      <p className="flex-grow font-light ">{title}</p>
    </div>
  );
};
