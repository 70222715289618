// @ts-check
import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Link2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { SelectShareUser } from "components/organisms/SelectShareUser";
import { CustomButton } from "components/atoms/CustomButton";

import { useAuth } from "../../../context/AuthContext";

import { ACCESS_OPTIONS } from "./constants";

import { User } from "domainModels/User";
import { UserList } from "components/organisms/UserList";
import { Capsule } from "domainModels/Capsule";
import { CustomSelect } from "components/organisms/CustomSelect";
import { PublicShareLink } from "domainModels/PublicShareLink";

/**
 *
 * @param {object} params
 * @param {string} params.capsuleName
 * @param {string} params.capsuleId
 * @param {boolean} params.show
 * @param {function} params.setShow
 * @returns
 */
export const ShareCapsuleModal = ({
  capsuleName,
  capsuleId,
  show,
  setShow,
}) => {
  const { currentUserRealTime: currentUser } = useAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [network, setNetwork] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [alreadySharedWithPeople, setAlreadySharedPeople] = useState([]);
  const [alreadySharedUserIds, setAlreadySharedUserIds] = useState([]);

  const [selectedAccess, setSelectedAccess] = useState(ACCESS_OPTIONS[0]);

  const [link, setLink] = useState("");

  const selectShareRef = useRef();

  const getUsersSharedWith = async ({ postId }) => {
    if (!postId) return;

    const peopleSharedWith = await Capsule.getPeopleSharedWith(capsuleId);

    let peopleListItems = peopleSharedWith.map((person) => ({
      title: person?.name ?? person.email,
      subtitle: person?.role ?? "pending",
      profilePictureUrl: person?.profilePicUrl || "",
      username: person?.username ?? null,
      email: person?.email,
    }));

    const sharedWithIds = peopleSharedWith
      .filter((person) => !!person.id)
      .map((person) => person.id);

    setAlreadySharedUserIds(sharedWithIds);

    setAlreadySharedPeople(peopleListItems);
  };

  const fetchNetwork = async () => {
    dispatch({ type: "showLoading" });
    try {
      const network = await User.getUserNetwork(currentUser?.uid);
      setNetwork(network);
      dispatch({ type: "hideLoading" });
    } catch (error) {
      dispatch({ type: "hideLoading" });
      console.log(error);
    }
  };

  const onSharePost = async () => {
    dispatch({ type: "showLoading" });
    const user = await User.getById(currentUser?.uid);

    const selectedUsersEmails = selectedUsers
      .filter((user) => user.__isNew__)
      .map((user) => user.value);

    const selectedUsersIds = selectedUsers
      .filter((user) => !user.__isNew__)
      .map((user) => user.id);

    try {
      await user.sharePostAndInviteByEmail(capsuleId, selectedUsersEmails);
      await user.sharePostAndInviteUsers(capsuleId, selectedUsersIds);

      toast.success("Post shared successfully");
      dispatch({ type: "hideLoading" });
      const sharedWithNewPeople = selectedUsers.map((user) => ({
        title: user.label,
        subtitle: user.sub ?? "pending",
        profilePictureUrl: "",
        username: "",
      }));
      setAlreadySharedPeople([
        ...alreadySharedWithPeople,
        ...sharedWithNewPeople,
      ]);
      if (selectShareRef?.current) {
        // @ts-ignore
        selectShareRef.current?.clearSelected();
      }
      setSelectedUsers([]);
      return;
    } catch (error) {
      dispatch({ type: "hideLoading" });
      toast.error(error.message);
      console.log(error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard");
  };

  const getRestrictedAccessLink = (capsuleId) => {
    return `${window.location.origin}/post-s-grid/${capsuleId}`;
  };

  const onAccessChange = async (selected) => {
    setSelectedAccess(selected);

    const publicLink = await PublicShareLink.getOrCreate({
      capsuleId,
      sharedByUserId: currentUser?.uid,
    });

    if (!publicLink) {
      toast.error(
        "There was an error while updating the access permissions, please try again later"
      );
      return;
    }

    publicLink.updateType(selected.value);
    let link = getRestrictedAccessLink(capsuleId);
    if (selected.value === "public") {
      link = publicLink.getFullURL();
    }

    setLink(link);
    toast.success("Access permissions updated successfully");
  };

  const getCurrentAccess = async ({ capsuleId, userId }) => {
    const publicLink = await PublicShareLink.get({
      capsuleId,
      sharedByUserId: userId,
    });

    if (publicLink && publicLink.type === "public") {
      setSelectedAccess(ACCESS_OPTIONS[1]);
      setLink(publicLink.getFullURL());
      return;
    }

    setSelectedAccess(ACCESS_OPTIONS[0]);
    setLink(getRestrictedAccessLink(capsuleId));
  };

  /**
   *
   * @param {string} username
   */
  const onUserItemClick = (username) => {
    if (!(username.length > 0)) return;
    navigate("/profile/" + username);
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchNetwork();
    getUsersSharedWith({ postId: capsuleId });
    getCurrentAccess({ capsuleId, userId: currentUser.uid });
  }, [currentUser, capsuleId]);

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header className="w-full">
        <Modal.Title className="w-full">
          <div className="!font-secondary !text-xl">Share "{capsuleName}"</div>
          <div className="flex w-full justify-between text-base font-light !mt-5">
            <SelectShareUser
              ref={selectShareRef}
              // @ts-ignore
              shareUsersList={network}
              currentUserId={currentUser?.uid}
              network={network}
              alreadySharedUsersIds={alreadySharedUserIds}
              onChange={(users) => setSelectedUsers(users)}
            />
            <CustomButton
              isDisabled={selectedUsers.length < 1}
              className="ml-3 !h-auto"
              onClick={onSharePost}
            >
              Invite
            </CustomButton>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="!mt-5">
        <div>
          <h2 className="text-lg">People shared with</h2>
          <UserList
            userList={alreadySharedWithPeople}
            onUserItemClick={onUserItemClick}
          />
        </div>

        <div id="general-access" className="!mt-7">
          <h2 className="text-lg">General Access</h2>
          <CustomSelect
            options={ACCESS_OPTIONS}
            onChange={onAccessChange}
            value={selectedAccess}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-between !mt-5">
        <CustomButton onClick={copyLink} variant="secondary">
          <div className="flex  items-center">
            <span className="mr-1">Copy Link</span> <Link2 size={15} />
          </div>
        </CustomButton>

        <CustomButton onClick={handleClose} variant="primary">
          Done
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};
