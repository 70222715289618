// @ts-check
import React, { useMemo, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  ProgressBar,
} from "react-bootstrap";

import { DragAndDrop } from "components/atoms/inputs/DragAndDrop";
import { CustomButton } from "components/atoms/CustomButton";
import { X } from "lucide-react";

/**
 * @param {object} props
 * @param {number | null} props.uploadProgress
 * @param {(selectedFile:File)=>void} props.onSubmit
 * @param {string} props.title
 * @param {(string)=>void} props.setTitle
 * @param {string} props.description
 * @param {boolean} props.isUploadFinished 
 * @param {boolean} props.showValidation
 * @param {(string)=>void} props.setDescription
 */

const UploadForm = ({
  uploadProgress,
  onSubmit,
  title,
  showValidation,
  isUploadFinished,
  setTitle,
  description,
  setDescription,
}) => {
  /**
   * @type {[File|undefined,import("react").Dispatch<(prevState:File|undefined) => File|undefined>]}
   */
  const [selectedFile, setSelectedFile] = useState();

  const handleSubmit = () => {
    if (selectedFile) {
      onSubmit(selectedFile);
    }
  };

   const progress = useMemo(()=>{
    if(isUploadFinished) return uploadProgress
    else return Math.min(uploadProgress,99)
   },[isUploadFinished,uploadProgress])

  /**
   * @param {File} file
   */
  const onFileSelectorChange = (file) => {
    setSelectedFile(() => file);
  };

  return (
    <div className="max-w-2xl mx-[20px] md:mx-auto ">
      <h3 className="text-center mb-3 md:mt-4">Upload Capsule</h3>
      <p className="font-extralight text-center mb-4 ">
        This Capsule will not be visible until you have shared it with users in
        your network.
      </p>
      <Form validated={showValidation}>
        <FormGroup className="mb-3 h-100 d-flex flex-column justify-content-center">
          <FormLabel>
            Title of Capsule{" "}
            <i className="italic">
              (i.e. Gallery Name - Artist Name - Exhibition Name)
            </i>
          </FormLabel>
          <FormControl
            type="text"
            placeholder="Capsule Title"
            value={title}
            required
            onChange={(e) => setTitle(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            This value is required
          </Form.Control.Feedback>
        </FormGroup>

        <FormGroup className="mb-3 h-100 d-flex flex-column justify-content-center">
          <label className="text-sm font-extralight">
            Description (optional)
          </label>
          <FormControl
            as="textarea"
            rows={6}
            type="text"
            placeholder="Add a small description to this Capsule"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormGroup>
        {!isUploadFinished && (
          <DragAndDrop
            onChange={onFileSelectorChange}
            onError={(e) => {
              console.log(e);
            }}
          ></DragAndDrop>
        )}

        <UploadProgressBar
          progress={progress}
          file={selectedFile}
          onSubmit={handleSubmit}
          onClose={() => setSelectedFile(() => undefined)}
        ></UploadProgressBar>
      </Form>
    </div>
  );
};
/**
 * @param {object} props
 * @param {number} props.progress
 * @param {File|undefined} props.file
 *  @param {()=>void} props.onSubmit
 * @param {()=>void} props.onClose
 */
const UploadProgressBar = ({ progress, file, onSubmit,onClose }) => {
  const fileSizeText = useMemo(() => {
    if (file?.size < 1000000)
      return `${Math.round(file?.size / 1024).toFixed(2)}kb`;
    return `${(Math.round(file?.size / 1024) / 1000).toFixed(2)}MB`;
  }, [file]);

  const isLoading = progress > 0;
  if (!file || progress == 100) return;
  return (
    <div>
      <div className="bg-light-gray rounded-md p-[20px] flex flex-col w-full md:flex-row align-middle">
        <div className={`flex flex-row ${isLoading ? "" : "w-full"}`}>
          <div className="mr-2">
            <div className="text-sm  font-light mb-2 !break-all">
              {file?.name}
            </div>
            <div className="text-sm font-light">{fileSizeText}</div>
          </div>
          {!isLoading && (
            <div className="flex flex-row ml-auto">
              <CustomButton
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
                className="w-28 h-10 ml-auto my-auto text-base"
              >
                Upload
              </CustomButton>
              <X
                className="my-auto ml-1 hover:cursor-pointer stroke-1"
                onClick={onClose}
              ></X>
            </div>
          )}
        </div>

        {isLoading && (
          <div className="w-full flex flex-row mt-3 md:!mt-0 items-center">
            <ProgressBar
              className="w-full mr-3 md:ml-3"
              variant="dark"
              now={progress}
            />
            <span className="">{progress}%</span>
          </div>
        )}
      </div>
      {isLoading && (
      <div className="w-full text-center text-xs">
        Uploading your Preview. Please don't close this window.
      </div>)}
    </div>
  );
};

export default UploadForm;
