// @ts-check

import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "firebaseConfig";

export class Page {
  createdAt = "";
  id = "";
  imageURL = "";
  notes = "";
  page = "";
  postId = "";
  postUserId = "";
  text = "";

  /**
   *
   * @param {object} params
   * @param {string} params.createdAt
   * @param {string} params.id
   * @param {string} params.imageURL
   * @param {string} params.notes
   * @param {string} params.page
   * @param {string} params.postId
   * @param {string} params.postUserId
   * @param {string} params.text
   *
   */
  constructor({
    createdAt,
    id,
    imageURL,
    notes,
    page,
    postId,
    postUserId,
    text,
  }) {
    this.createdAt = createdAt;
    this.id = id;
    this.imageURL = imageURL;
    this.notes = notes;
    this.page = page;
    this.postId = postId;
    this.postUserId = postUserId;
    this.text = text;
  }

  static convertManyFromFirestore(snapshots) {
    return snapshots.map((snapshot) => {
      const data = snapshot.data();
      return new Page(data);
    });
  }

  /**
   *
   * @param {string} capsuleId
   * @returns Page[]
   */

  static async getPagesFromCapsule(capsuleId) {
    const ref = collection(db, "posts", capsuleId, "pages");
    const docs = (await getDocs(query(ref, orderBy("page", "asc")))).docs;
    const pages = Page.convertManyFromFirestore(docs);
    return pages;
  }
}
