import { Avatar } from "components/atoms/Avatar";

export const UserListItem = ({
  title,
  subtitle,
  profilePictureUrl,
  onClick,
  className = "",
}) => {
  return (
    <div className={`flex w-full items-center ${className}`} onClick={onClick}>
      <Avatar src={profilePictureUrl} name={title} />
      <div className="pl-3">
        <p className="font-regular mb-.5">{title}</p>
        <p className="text-tertiary-gray capitalize text-sm">{subtitle}</p>
      </div>
    </div>
  );
};
