import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";

import {
  IoCloseOutline,
  IoAddSharp,
  IoChevronUpOutline,
  IoChevronDownOutline,
} from "react-icons/io5";

import * as formik from "formik";
import * as yup from "yup";
import { Field, getIn, FieldArray } from "formik";
import { useAuth } from "../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { sendNotification } from "../utils/notification";
import { User } from "domainModels/User";

function Profile() {
  const { Formik } = formik;

  const params = useParams();

  const { currentUser, isConnoisseur } = useAuth();

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isInMyNetwork, setIsInMyNetwork] = useState(true);

  const dispatch = useDispatch();

  const submitRef = useRef(null);
  const { loading } = useSelector((store) => store);

  const clickSubmit = () => {
    submitRef.current?.click();

    dispatch({ type: "showLoading" });
    setTimeout(() => {
      dispatch({ type: "hideLoading" });
    }, 1000);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isMe = () =>
    !params.userId || (currentUser ? currentUser?.uid === user?.uid : false);

  const collectorData = {
    firstName: "",
    lastName: "",
    username: "",
    occupation: "",
    age: "",
    locationCity: "",
    locationCountry: "",

    primaryAdvisor: "",

    museumAffils: [],
    wishlist: [],
    collectionArtists: [],
    galleries: [],

    file: null,
  };

  const getData = async () => {
    dispatch({ type: "showLoading" });

    var userId = null;
    var userUsername = null;

    if (params.userId) {
      const result = await getDoc(doc(db, "usernames", params.userId));
      const usernameData = result.data();

      if (!usernameData) {
        dispatch({ type: "hideLoading" });
        toast.error("Username not found");
        return;
      }
      userId = usernameData?.userId;
      userUsername = params?.userId;

      const userInNetwork = await getDoc(
        doc(db, "users", currentUser?.uid, "network", userId)
      );
      setIsInMyNetwork(userInNetwork.exists());

      setUser({ uid: userId });
    } else {
      userId = currentUser?.uid;

      await getDoc(doc(db, "users", userId))
        .then((response) => {
          userUsername = response.data().username;
          setUser({ ...response.data(), uid: response.id });
        })
        .catch((err) => {
          dispatch({ type: "hideLoading" });
          toast.error("User not found");
        });
    }

    const result = await getDoc(doc(db, "users", userId, "public", "profile"));
    const data = result.data();

    if (!data) {
      setProfile({ ...collectorData, username: userUsername });
      dispatch({ type: "hideLoading" });
    } else {
      setProfile({ ...collectorData, ...data, username: userUsername });
      dispatch({ type: "hideLoading" });
    }
  };

  const usernameIsAvailable = async (username) => {
    const usernameDoc = await getDoc(doc(db, "usernames", username));
    if (!usernameDoc.exists()) return true;
    else if (usernameDoc.exists() && usernameDoc.data().isAvailable)
      return true;
    else return false;
  };

  const updateProfile = async (profile) => {
    dispatch({ type: "showLoading" });

    if (user?.displayName !== profile.name) {
      await updateDoc(doc(db, "users", currentUser?.uid), {
        displayName: profile.name,
      }).catch((err) => {
        toast.error("Error updating collector profile?. Please try again.");
        dispatch({ type: "hideLoading" });
      });
    }

    if (user?.username !== profile.username) {
      const isAvailable = await usernameIsAvailable(profile.username);
      if (!isAvailable) {
        dispatch({ type: "hideLoading" });
        toast.error("Username already in use. Please try again.");
        return;
      }

      await updateDoc(doc(db, "users", currentUser?.uid), {
        username: profile.username,
      }).catch((err) => {
        toast.error("Error updating collector profile?. Please try again.");
        dispatch({ type: "hideLoading" });
      });

      setDoc(doc(db, "usernames", profile.username), {
        isAvailable: false,
        userId: user?.uid,
        createdAt: moment().format("YYYY-MM-DD hh:mm:ss a"),
      })
        .then(() => {
          setDoc(doc(db, "usernames", user?.username), {
            isAvailable: true,
            userId: null,
          }).catch((err) => {
            toast.error("Error updating collector profile?. Please try again.");
            dispatch({ type: "hideLoading" });
          });
        })
        .catch((err) => {
          toast.error("Error updating collector profile?. Please try again.");
          dispatch({ type: "hideLoading" });
        });
    }

    delete profile.username;

    setDoc(doc(db, "users", user?.uid, "public", "profile"), profile)
      .then(async () => {
        getData();
        toast.success("Collector profile updated successfully");
        dispatch({ type: "hideLoading" });
        handleClose();
      })
      .catch((e) => {
        console.error("Profile update error: ", e);
        toast.error("Error updating collector profile?. Please try again.");
        dispatch({ type: "hideLoading" });
      });
  };

  const addToNetwork = async () => {
    try {
      const userRequesting = await User.getById(currentUser?.uid);
      const userToAdd = await User.getById(user?.uid);

      await userRequesting.inviteUserToNetwork(userToAdd);
      setIsInMyNetwork(true);
      toast.success(`Invite sent to @${userToAdd?.username}`);
    } catch (err) {
      toast.error(`Failed to send invite to @${user?.username}`);
    }
  };

  const schema = yup.object().shape({
    //firstName: yup.string().required("Required"),
    //lastName: yup.string().required("Required"),
    name: yup.string().required("Required"),
    username: yup.string().required("Required"),
    age: yup.string().nullable().notRequired(),
    occupation: yup.string(),
    locationCity: yup.string(),
    locationCountry: yup.string(),
    primaryAdvisor: yup.string().nullable().notRequired(),
    galleries: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .min(2, "Gallery name must be longer than two characters")
            .nullable()
            .notRequired(), // these constraints take precedence
        })
      )
      .nullable()
      .notRequired() // these constraints are shown if and only if inner constraints are satisfied
      .min(0, "Minimum of three galleries"),

    museumAffils: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .min(2, "Museum name must be longer than two characters")
            .nullable()
            .notRequired(), // these constraints take precedence
        })
      )
      .nullable()
      .notRequired() // these constraints are shown if and only if inner constraints are satisfied
      .min(0, "Minimum of three museums"),

    collectionArtists: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .min(2, "Artist name must be longer than two characters")
            .nullable()
            .notRequired(), // these constraints take precedence
        })
      )
      .nullable()
      .notRequired() // these constraints are shown if and only if inner constraints are satisfied
      .min(0, "Minimum of three artists in collection"),

    wishlist: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .min(2, "Artist name must be longer than two characters")
            .nullable()
            .notRequired(), // these constraints take precedence
        })
      )
      .nullable()
      .notRequired() // these constraints are shown if and only if inner constraints are satisfied
      .min(0, "Minimum of three artists on wishlist"),

    file: yup.mixed().nullable().notRequired(),
  });

  useEffect(() => {
    getData();
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      <>
        <span className="">
          <Modal
            show={show}
            onHide={handleClose}
            fullscreen="xl-down"
            size="xl"
            centered
            scrollable
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit public profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              <Formik
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  updateProfile(values);
                }}
                initialValues={profile}
              >
                {({ values, touched, errors, handleSubmit, handleChange }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-2 mb-sm-4">
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik101"
                        className="position-relative mb-3 mb-sm-2"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name}
                          isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Control.Feedback tooltip>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik101"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="First name"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isValid={touched.firstName && !errors.firstName}
                            isInvalid={touched.firstName && !!errors.firstName}
                          />
                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik102"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Last name"
                            name="lastName"
                            value={values?.lastName}
                            onChange={handleChange}
                            isValid={touched?.lastName && !errors?.lastName}
                            isInvalid={touched?.lastName && !!errors?.lastName}
                          />

                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors?.lastName}
                          </Form.Control.Feedback>
                        </Form.Group> */}
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik105"
                        className="position-relative mb-3 mb-sm-2"
                      >
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Username"
                          name="username"
                          value={values?.username}
                          onChange={handleChange}
                          disabled
                          isValid={touched?.username && !errors?.username}
                          isInvalid={touched?.username && !!errors?.username}
                        />

                        <Form.Control.Feedback tooltip>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors?.username}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2 mb-sm-4">
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik105"
                        className="position-relative mb-3 mb-sm-2"
                      >
                        <Form.Label>Age</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Age"
                          name="age"
                          value={values?.age}
                          onChange={handleChange}
                          isValid={touched?.age && !errors?.age}
                          isInvalid={touched?.age && !!errors?.age}
                        />

                        <Form.Control.Feedback tooltip>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors?.age}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik102"
                        className="position-relative mb-3 mb-sm-2"
                      >
                        <Form.Label>Occupation</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Occupation title"
                          name="occupation"
                          value={values?.occupation}
                          onChange={handleChange}
                          isValid={touched?.occupation && !errors?.occupation}
                          isInvalid={
                            touched?.occupation && !!errors?.occupation
                          }
                        />

                        <Form.Control.Feedback tooltip>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors?.occupation}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik103"
                        className="position-relative mb-3 mb-sm-2"
                      >
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="City"
                          name="locationCity"
                          value={values?.locationCity}
                          onChange={handleChange}
                          isValid={
                            touched?.locationCity && !errors?.locationCity
                          }
                          isInvalid={
                            touched?.locationCity && !!errors?.locationCity
                          }
                        />

                        <Form.Control.Feedback tooltip>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors?.locationCity}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-2 mb-sm-4">
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik104"
                        className="position-relative mb-3 mb-sm-2"
                      >
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Country"
                          name="locationCountry"
                          value={values?.locationCountry}
                          onChange={handleChange}
                          isValid={
                            touched?.locationCountry && !errors?.locationCountry
                          }
                          isInvalid={
                            touched?.locationCountry &&
                            !!errors?.locationCountry
                          }
                        />
                        <Form.Control.Feedback tooltip>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors?.locationCountry}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik105"
                        className="position-relative mb-3 mb-sm-2"
                      >
                        <Form.Label>Advisor</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Primary advisor"
                          name="primaryAdvisor"
                          value={values?.primaryAdvisor}
                          onChange={handleChange}
                          isValid={
                            touched?.primaryAdvisor && !errors?.primaryAdvisor
                          }
                          isInvalid={
                            touched?.primaryAdvisor && !!errors?.primaryAdvisor
                          }
                        />

                        <Form.Control.Feedback tooltip>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors?.primaryAdvisor}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/*<Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik105"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Profile image</Form.Label>
                          <Form.Control
                            type="file"
                            required
                            name="file"
                            onChange={handleChange}
                            isValid={touched?.file && !errors?.file}
                            isInvalid={touched?.file && !!errors?.file}
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors?.file}
                          </Form.Control.Feedback>
                          </Form.Group>*/}
                    </Row>

                    <Row className="mb-2 mb-sm-4">
                      <Form.Group
                        as={Col}
                        sm="12"
                        md="6"
                        xl="6"
                        controlId="validationFormik101"
                        className="position-relative mb-3 mb-sm-2"
                        name="galleries"
                      >
                        <Form.Label>Galleries affiliations</Form.Label>
                        <FieldArray
                          name="galleries"
                          render={(arrayHelpers) => {
                            const galleriesSet = values?.galleries;
                            // const OuterArrayErrors = ({ name }) => {
                            //   return (
                            //     <Field
                            //       name={name}
                            //       render={({ form }) => {
                            //         const group = name.split("[")[0];

                            //         if (typeof errors[`${group}`] === "string") {
                            //           return errors[`${group}`];
                            //         } else {
                            //           return null;
                            //         }
                            //       }}
                            //     />
                            //   );
                            // };
                            const ErrorMessage = ({ name }) => {
                              return (
                                <Field name={name}>
                                  {({ field, form, meta }) => {
                                    const group = name.split("[")[0];
                                    // console.log("Group: ", group);
                                    const error = getIn(form.errors, name);
                                    const touch = getIn(form.touched, name);
                                    const stringCheck =
                                      typeof errors[`${group}`] === "string"
                                        ? errors[`${group}`]
                                        : null;
                                    return touch && error ? error : stringCheck;
                                  }}
                                </Field>
                              );
                            };
                            return (
                              <div>
                                <div className="row justify-content-start mb-2">
                                  <div className="col-1">
                                    {" "}
                                    <Button
                                      variant="outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        arrayHelpers.push({ name: "" });
                                      }}
                                    >
                                      <IoAddSharp className="text-center" />
                                    </Button>
                                  </div>
                                </div>

                                <div>
                                  {galleriesSet?.map(({ name }, index) => {
                                    return (
                                      <div key={index}>
                                        <Row className="mb-3">
                                          <Form.Group
                                            as={Col}
                                            md="12"
                                            controlId=""
                                            className="position-relative"
                                          >
                                            <div className="row g-0 justify-content-start">
                                              <div className="col-1 me-2 me-sm-2 me-md-2 me-lg-2 me-xl-0">
                                                {" "}
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    arrayHelpers.remove(index);
                                                  }}
                                                >
                                                  <IoCloseOutline className="text-center" />
                                                </Button>
                                              </div>
                                              <div className="col-8">
                                                {" "}
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Gallery name"
                                                  name={`galleries[${index}].name`}
                                                  value={
                                                    galleriesSet[index].name
                                                  }
                                                  onChange={handleChange}
                                                  isValid={
                                                    touched?.galleries &&
                                                    !errors?.galleries
                                                  }
                                                  isInvalid={
                                                    touched?.galleries &&
                                                    !!errors?.galleries
                                                  }
                                                />
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  tooltip
                                                >
                                                  {" "}
                                                  <ErrorMessage
                                                    name={`galleries[${index}].name`}
                                                  />
                                                </Form.Control.Feedback>
                                                {/* <ErrorMessageOuter /> */}
                                              </div>
                                              <div className="col ms-2">
                                                {" "}
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    let arrayEnd =
                                                      galleriesSet.length - 1;
                                                    if (index !== 0) {
                                                      arrayHelpers.swap(
                                                        index,
                                                        index - 1
                                                      );
                                                    } else {
                                                      arrayHelpers.swap(
                                                        index,
                                                        arrayEnd
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoChevronUpOutline className="text-center" />
                                                </Button>
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    let arrayEnd =
                                                      galleriesSet.length - 1;
                                                    if (index !== arrayEnd) {
                                                      arrayHelpers.swap(
                                                        index,
                                                        index + 1
                                                      );
                                                    } else {
                                                      arrayHelpers.swap(
                                                        index,
                                                        0
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoChevronDownOutline className="text-center" />
                                                </Button>
                                              </div>
                                            </div>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm="12"
                        md="6"
                        xl="6"
                        controlId="validationFormik101"
                        className="position-relative mb-3 mb-sm-2"
                        name="museumAffils"
                      >
                        <Form.Label>Museum affiliations</Form.Label>
                        <FieldArray
                          name="museumAffils"
                          render={(arrayHelpers) => {
                            const museumAffilsSet = values?.museumAffils;
                            const ErrorMessage = ({ name }) => {
                              return (
                                <Field name={name}>
                                  {({ field, form, meta }) => {
                                    const group = name.split("[")[0];
                                    // console.log("Group: ", group);
                                    const error = getIn(form.errors, name);
                                    const touch = getIn(form.touched, name);
                                    const stringCheck =
                                      typeof errors[`${group}`] === "string"
                                        ? errors[`${group}`]
                                        : null;
                                    return touch && error ? error : stringCheck;
                                  }}
                                </Field>
                              );
                            };
                            return (
                              <div>
                                <div className="row justify-content-start mb-2">
                                  <div className="col-1">
                                    {" "}
                                    <Button
                                      variant="outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        arrayHelpers.push({ name: "" });
                                      }}
                                    >
                                      <IoAddSharp className="text-center" />
                                    </Button>
                                  </div>
                                </div>

                                <div>
                                  {museumAffilsSet?.map(({ name }, index) => {
                                    return (
                                      <div key={index}>
                                        <Row className="mb-3">
                                          <Form.Group
                                            as={Col}
                                            md="12"
                                            controlId=""
                                            className="position-relative"
                                          >
                                            <div className="row g-0 justify-content-start">
                                              <div className="col-1 me-2 me-sm-2 me-md-2 me-lg-2 me-xl-0">
                                                {" "}
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    arrayHelpers.remove(index);
                                                  }}
                                                >
                                                  <IoCloseOutline className="text-center" />
                                                </Button>
                                              </div>
                                              <div className="col-8">
                                                {" "}
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Museum name"
                                                  name={`museumAffils[${index}].name`}
                                                  value={
                                                    museumAffilsSet[index].name
                                                  }
                                                  onChange={handleChange}
                                                  isValid={
                                                    touched?.museumAffils &&
                                                    !errors?.museumAffils
                                                  }
                                                  isInvalid={
                                                    touched?.museumAffils &&
                                                    !!errors?.museumAffils
                                                  }
                                                />
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  tooltip
                                                >
                                                  {" "}
                                                  <ErrorMessage
                                                    name={`museumAffils[${index}].name`}
                                                  />
                                                </Form.Control.Feedback>
                                                {/* <ErrorMessageOuter /> */}
                                              </div>
                                              <div className="col ms-2">
                                                {" "}
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    let arrayEnd =
                                                      museumAffilsSet.length -
                                                      1;
                                                    if (index !== 0) {
                                                      arrayHelpers.swap(
                                                        index,
                                                        index - 1
                                                      );
                                                    } else {
                                                      arrayHelpers.swap(
                                                        index,
                                                        arrayEnd
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoChevronUpOutline className="text-center" />
                                                </Button>
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    let arrayEnd =
                                                      museumAffilsSet.length -
                                                      1;
                                                    if (index !== arrayEnd) {
                                                      arrayHelpers.swap(
                                                        index,
                                                        index + 1
                                                      );
                                                    } else {
                                                      arrayHelpers.swap(
                                                        index,
                                                        0
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoChevronDownOutline className="text-center" />
                                                </Button>
                                              </div>
                                            </div>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-2 mb-sm-4">
                      <Form.Group
                        as={Col}
                        sm="12"
                        md="6"
                        xl="6"
                        controlId="validationFormik101"
                        className="position-relative mb-3 mb-sm-2"
                        name="collectionArtists"
                      >
                        <Form.Label>Artists in collection</Form.Label>
                        <FieldArray
                          name="collectionArtists"
                          render={(arrayHelpers) => {
                            const collectionArtistsSet =
                              values?.collectionArtists;
                            const ErrorMessage = ({ name }) => {
                              return (
                                <Field name={name}>
                                  {({ field, form, meta }) => {
                                    const group = name.split("[")[0];
                                    // console.log("Group: ", group);
                                    const error = getIn(form.errors, name);
                                    const touch = getIn(form.touched, name);
                                    const stringCheck =
                                      typeof errors[`${group}`] === "string"
                                        ? errors[`${group}`]
                                        : null;
                                    return touch && error ? error : stringCheck;
                                  }}
                                </Field>
                              );
                            };
                            return (
                              <div>
                                <div className="row justify-content-start mb-2">
                                  <div className="col-1">
                                    {" "}
                                    <Button
                                      variant="outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        arrayHelpers.push({ name: "" });
                                      }}
                                    >
                                      <IoAddSharp className="text-center" />
                                    </Button>
                                  </div>
                                </div>

                                <div>
                                  {collectionArtistsSet?.map(
                                    ({ name }, index) => {
                                      return (
                                        <div key={index}>
                                          <Row className="mb-3">
                                            <Form.Group
                                              as={Col}
                                              md="12"
                                              controlId=""
                                              className="position-relative"
                                            >
                                              <div className="row g-0 justify-content-start">
                                                <div className="col-1 me-2 me-sm-2 me-md-2 me-lg-2 me-xl-0">
                                                  {" "}
                                                  <Button
                                                    variant="light"
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    <IoCloseOutline className="text-center" />
                                                  </Button>
                                                </div>
                                                <div className="col-8">
                                                  {" "}
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Artist name"
                                                    name={`collectionArtists[${index}].name`}
                                                    value={
                                                      collectionArtistsSet[
                                                        index
                                                      ].name
                                                    }
                                                    onChange={handleChange}
                                                    isValid={
                                                      touched?.collectionArtists &&
                                                      !errors?.collectionArtists
                                                    }
                                                    isInvalid={
                                                      touched?.collectionArtists &&
                                                      !!errors?.collectionArtists
                                                    }
                                                  />
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    tooltip
                                                  >
                                                    {" "}
                                                    <ErrorMessage
                                                      name={`collectionArtists[${index}].name`}
                                                    />
                                                  </Form.Control.Feedback>
                                                  {/* <ErrorMessageOuter /> */}
                                                </div>
                                                <div className="col ms-2">
                                                  {" "}
                                                  <Button
                                                    variant="light"
                                                    onClick={() => {
                                                      let arrayEnd =
                                                        collectionArtistsSet.length -
                                                        1;
                                                      if (index !== 0) {
                                                        arrayHelpers.swap(
                                                          index,
                                                          index - 1
                                                        );
                                                      } else {
                                                        arrayHelpers.swap(
                                                          index,
                                                          arrayEnd
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <IoChevronUpOutline className="text-center" />
                                                  </Button>
                                                  <Button
                                                    variant="light"
                                                    onClick={() => {
                                                      let arrayEnd =
                                                        collectionArtistsSet.length -
                                                        1;
                                                      if (index !== arrayEnd) {
                                                        arrayHelpers.swap(
                                                          index,
                                                          index + 1
                                                        );
                                                      } else {
                                                        arrayHelpers.swap(
                                                          index,
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <IoChevronDownOutline className="text-center" />
                                                  </Button>
                                                </div>
                                              </div>
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm="12"
                        md="6"
                        xl="6"
                        controlId="validationFormik101"
                        className="position-relative mb-3 mb-sm-2"
                        name="wishlist"
                      >
                        <Form.Label>Artists on wishlist</Form.Label>
                        <FieldArray
                          name="wishlist"
                          render={(arrayHelpers) => {
                            const wishlistSet = values?.wishlist;
                            const ErrorMessage = ({ name }) => {
                              return (
                                <Field name={name}>
                                  {({ field, form, meta }) => {
                                    const group = name.split("[")[0];
                                    // console.log("Group: ", group);
                                    const error = getIn(form.errors, name);
                                    const touch = getIn(form.touched, name);
                                    const stringCheck =
                                      typeof errors[`${group}`] === "string"
                                        ? errors[`${group}`]
                                        : null;
                                    return touch && error ? error : stringCheck;
                                  }}
                                </Field>
                              );
                            };
                            return (
                              <div>
                                <div className="row justify-content-start mb-2">
                                  <div className="col-1">
                                    {" "}
                                    <Button
                                      variant="outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        arrayHelpers.push({ name: "" });
                                      }}
                                    >
                                      <IoAddSharp className="text-center" />
                                    </Button>
                                  </div>
                                </div>

                                <div>
                                  {wishlistSet?.map(({ name }, index) => {
                                    return (
                                      <div key={index}>
                                        <Row className="mb-3">
                                          <Form.Group
                                            as={Col}
                                            md="12"
                                            controlId=""
                                            className="position-relative"
                                          >
                                            <div className="row g-0 justify-content-start">
                                              <div className="col-1 me-2 me-sm-2 me-md-2 me-lg-2 me-xl-0">
                                                {" "}
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    arrayHelpers.remove(index);
                                                  }}
                                                >
                                                  <IoCloseOutline className="text-center" />
                                                </Button>
                                              </div>
                                              <div className="col-8">
                                                {" "}
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Artist name"
                                                  name={`wishlist[${index}].name`}
                                                  value={
                                                    wishlistSet[index].name
                                                  }
                                                  onChange={handleChange}
                                                  isValid={
                                                    touched?.wishlist &&
                                                    !errors?.wishlist
                                                  }
                                                  isInvalid={
                                                    touched?.wishlist &&
                                                    !!errors?.wishlist
                                                  }
                                                />
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  tooltip
                                                >
                                                  {" "}
                                                  <ErrorMessage
                                                    name={`wishlist[${index}].name`}
                                                  />
                                                </Form.Control.Feedback>
                                                {/* <ErrorMessageOuter /> */}
                                              </div>
                                              <div className="col ms-2">
                                                {" "}
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    let arrayEnd =
                                                      wishlistSet.length - 1;
                                                    if (index !== 0) {
                                                      arrayHelpers.swap(
                                                        index,
                                                        index - 1
                                                      );
                                                    } else {
                                                      arrayHelpers.swap(
                                                        index,
                                                        arrayEnd
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoChevronUpOutline className="text-center" />
                                                </Button>
                                                <Button
                                                  variant="light"
                                                  onClick={() => {
                                                    let arrayEnd =
                                                      wishlistSet.length - 1;
                                                    if (index !== arrayEnd) {
                                                      arrayHelpers.swap(
                                                        index,
                                                        index + 1
                                                      );
                                                    } else {
                                                      arrayHelpers.swap(
                                                        index,
                                                        0
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoChevronDownOutline className="text-center" />
                                                </Button>
                                              </div>
                                            </div>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <Button
                      variant="outline-dark"
                      type="submit"
                      ref={submitRef}
                      className="d-none"
                    >
                      Update
                    </Button>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="outline-dark"
                type="button"
                onClick={clickSubmit}
              >
                {loading ? <Loader /> : "Update"}
              </Button>
            </Modal.Footer>
          </Modal>
        </span>
        <Card className="mb-5">
          <Card.Header
            as="h5"
            className="d-flex row justify-content-between g-0"
          >
            <div className="col text-start"></div>
            <div className="col text-center">
              {isMe() ? "MY PUBLIC PROFILE" : `PROFILE - @${params?.userId}`}
            </div>
            <div className="col text-end">
              {!isInMyNetwork && (
                <Link
                  className="cursor-pointer text-blue-500 z-50 relative"
                  onClick={addToNetwork}
                >
                  + add to network
                </Link>
              )}
            </div>
          </Card.Header>

          <Card.Body>
            <div className="container px-4">
              {isMe() && (
                <div className="d-flex justify-content-center mb-2">
                  {" "}
                  <Button
                    variant="outline-secondary"
                    onClick={handleShow}
                    className="h-100 text-center p-0 px-2 mt-0"
                    style={{
                      fontWeight: "380",
                      fontSize: ".90rem",
                      fontStyle: "italic",
                    }}
                  >
                    {" "}
                    edit profile
                  </Button>
                </div>
              )}
              <div className="row g-5">
                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <div className="row my-3">Name: {profile?.name}</div>
                  <div className="row my-3">Username: {profile?.username}</div>
                  <div className="row my-3">Age: {profile?.age}</div>
                  <div className="row my-3">
                    Occupation: {profile?.occupation}
                  </div>
                  <div className="row my-3">
                    Location: {profile?.locationCity},{" "}
                    {profile?.locationCountry}
                  </div>
                  <div className="row my-3">
                    Art advisor: {profile?.primaryAdvisor}
                  </div>
                </div>

                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <div className="row my-3">
                    Wishlist:{" "}
                    {profile?.wishlist?.map((wish) => {
                      return (
                        <div className="ms-2" key={wish.name}>
                          {wish.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                  {" "}
                  <div className="row my-3">
                    Artists in Collection:
                    {profile?.collectionArtists?.map((collArtist) => {
                      return (
                        <div className="ms-2" key={collArtist.name}>
                          {collArtist.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                  {" "}
                  <div className="row my-3">
                    Galleries Worked With:{" "}
                    {profile?.galleries?.map((gallery) => {
                      return (
                        <div className="ms-2" key={gallery.name}>
                          {gallery.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <div className="row my-3 mb-3">
                    Museum Affiliations:
                    {profile?.museumAffils?.map((museum) => {
                      return (
                        <div className="ms-2" key={museum.name}>
                          {museum.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </>
    </DefaultLayout>
  );
}

export default Profile;
