import React, { useCallback, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useNavigate } from "react-router-dom";

/**
 * @param {Object} props
 * @param {import("utils/types").Capsule} props.post
 * @param {import("utils/types").Page} props.page
 * @param {boolean} props.show
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setShow
 * @returns
 */
function ImagePreviewModal({ post, page, show, setShow }) {
  const navigate = useNavigate();

  const imgRef = useRef();

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    // check if image exists
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  return (
    <Modal
      size="lg"
      show={show !== null}
      onHide={() => setShow(null)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <span className="text-muted h5">{post.description} </span>
          <span className="text-secondary h5">- page {page.page}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <div className="d-flex justify-content-center align-items-center">
          {" "}
          <QuickPinchZoom
            onUpdate={onUpdate}
            doubleTapToggleZoom={true}
            doubleTapZoomOutOnMaxScale={true}
            draggableUnZoomed
          >
            <img
              ref={imgRef}
              alt="img"
              src={page.imageURL}
              className="img-fluid rounded"
              style={{
                cursor: "pointer",
                maxHeight: "80vh",
                width: "100",
              }}
            />
          </QuickPinchZoom>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => setShow(null)}>
          Close
        </Button>
        <Button
          variant="outline-dark"
          onClick={() => navigate(`/post-o-page/${post.id}#${page}`)}
        >
          Slides
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImagePreviewModal;
