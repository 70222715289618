import "instantsearch.css/themes/satellite.css";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Configure,
  Hits,
  Index,
  SearchBox,
  useInstantSearch,
} from "react-instantsearch";
import { useAuth } from "../context/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { CustomButton } from "./atoms/CustomButton";
import {
  IncomingPreviewHit,
  OutgoingPreviewHit,
  PendingPurchaseHit,
  PendingSalesHit,
  UserHit,
  PageHit,
} from "./templates/searchHits";
import { BsXLg } from "react-icons/bs";

const CustomHits = ({ indexId, component, title, setShow }) => {
  const { scopedResults } = useInstantSearch();

  const results = scopedResults.find((sr) => sr.indexId === indexId);

  const show = results?.results?.nbHits > 0;

  if (!show) return null;

  return (
    <div>
      <h5 style={{ fontWeight: "bold" }}>{title}</h5>
      <Hits hitComponent={component} onClick={() => setShow(false)} />
    </div>
  );
};

const SearchModal = ({ show, setShow }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      style={{ overflow: "hidden" }}
    >
      <Modal.Header>
        <div className="flex w-full justify-end">
          <BsXLg className="cursor-pointer" onClick={() => setShow(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <SearchBox autoFocus placeholder="Search for anything in Romulus" />
        <FilteredHits show={show} setShow={setShow} />
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-start items-center w-full">
          <div className="flex items-center">
            <p className="text-main">Powered by Algolia</p>
            <img src="/algolia.png" width="40" alt="Algolia's Logo" />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const FilteredHits = ({ show, setShow }) => {
  const HitsCategories = Object.freeze({
    OutgoingPreviews: "Outgoing Capsules",
    PendingSales: "Pending Sales",
    IncomingPreviews: "Incoming Capsules",
    PendingPurchases: "Pending Purchases",
    //MyCollection: "My Collection",
    //MyFeedback: "My Feedback",
    Users: "Users",
  });

  const { currentUser, isConnoisseur, isVendor } = useAuth();
  const [sharedWithPosts, setSharedWithPosts] = useState([]);

  const getSharedPosts = () => {
    getDocs(
      query(
        collection(db, "sharedPosts"),
        where("sharedWith", "==", currentUser?.uid)
      )
    ).then((response) => {
      setSharedWithPosts(response.docs.map((doc) => doc.data()));
    });
  };

  useEffect(() => {
    getSharedPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @type {Array<Category>} selectedCategories array of selected categories
   */
  const [selectedCategories, setSelectedCategories] = useState([]);
  /**
   *
   * @param {String} category
   * @returns {Boolean} true if category is visible
   */
  const isCategoryVisible = (category) =>
    selectedCategories.includes(category) || selectedCategories.length === 0;

  const outgoingPreviewFilter = `userId:"${currentUser?.uid}"`
  const incomingPreviewFiler = sharedWithPosts
    .map((sp) => sp.post)
    .map((obj) => `objectID:${obj}`)
    .join(" OR ");

  const pagesFilter = `userId:"${currentUser?.uid}" ${
    sharedWithPosts.length!==0? "OR":""
  } ${sharedWithPosts
    .map((sp) => sp.post)
    .map((obj) => `postId:${obj}`)
    .join(" OR ")}`;

    const ordersFilter = `userId:"${currentUser?.uid}"`;
    const pendingSalesFilter = `shareInfo.sharedById:"${currentUser?.uid}"`;

  return (
    <>
      <CategoriesFilter
        categories={Object.values(HitsCategories)}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <div className="max-h-[60vh] tall:max-h-[70vh] overflow-auto">
        {isConnoisseur() &&
          isCategoryVisible(HitsCategories.OutgoingPreviews) && (
            <div className="mt-5">
              <Index indexName="post-outgoing" indexId="outgoing-posts">
                <Configure filters={outgoingPreviewFilter} />
                <CustomHits
                  indexId="outgoing-posts"
                  component={OutgoingPreviewHit}
                  title="Outgoing Capsules"
                  setShow={setShow}
                />
              </Index>
            </div>
          )}
        {!isVendor() && isCategoryVisible(HitsCategories.IncomingPreviews) && (
          <div className="mt-5">
            <Index indexName="posts" indexId="incoming-posts">
              <Configure
                filters={incomingPreviewFiler}
              />
              <CustomHits
                indexId="incoming-posts"
                component={IncomingPreviewHit}
                title="Incoming Capsules"
                setShow={setShow}
              />
            </Index>
          </div>
        )}
        {!isVendor() && selectedCategories.length === 0 && (
          <div className="mt-5">
            <Index indexName="pages" indexId="pages">
              <Configure
                filters={pagesFilter}
              />
              <CustomHits
                indexId="pages"
                component={PageHit}
                setShow={setShow}
              />
            </Index>
          </div>
        )}
        {isConnoisseur() && isCategoryVisible(HitsCategories.PendingSales) && (
          <div className="mt-5">
            <Index indexName="orders" indexId="orders-sales">
              <Configure
                filters={pendingSalesFilter}
              />
              <CustomHits
                indexId="orders-sales"
                component={PendingSalesHit}
                title="Pending Sales"
                setShow={setShow}
              />
            </Index>
          </div>
        )}
        {!isVendor() && isCategoryVisible(HitsCategories.PendingPurchases) && (
          <div className="mt-5">
            <Index indexName="orders" indexId="orders-purchases">
              <Configure filters={ordersFilter} />
              <CustomHits
                indexId="orders-purchases"
                component={PendingPurchaseHit}
                title="Pending Purchases"
                setShow={setShow}
              />
            </Index>
          </div>
        )}
        {isCategoryVisible(HitsCategories.Users) && (
          <div style={{ marginTop: "20px" }}>
            <Index indexName="users" indexId="users">
              <CustomHits
                indexId="users"
                component={UserHit}
                title="Users"
                setShow={setShow}
              />
            </Index>
          </div>
        )}
      </div>
    </>
  );
};

/**
 * @param {Array<Category>} selectedCategories array of selected categories
 * @param {Function} setSelectedCategories function to set selected categories
 * @returns JSX.Element
 */
const CategoriesFilter = ({
  categories,
  selectedCategories,
  setSelectedCategories,
}) => {
  return (
    <div className="mt-3">
      <p>I'm looking for</p>
      <div className="flex flex-wrap">
        {categories.map((category) => (
          <CustomButton
            key={category}
            onClick={() => {
              if (selectedCategories.includes(category)) {
                setSelectedCategories(
                  selectedCategories.filter((c) => c !== category)
                );
              } else {
                setSelectedCategories([...selectedCategories, category]);
              }
            }}
            variant={
              selectedCategories.includes(category) ? "primary" : "secondary"
            }
            className="h-9 !px-[4px] mr-1 min-w-fit !font-normal mt-1"
          >
            {category}
          </CustomButton>
        ))}
      </div>
    </div>
  );
};

export default SearchModal;
