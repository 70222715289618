// @ts-check
import React, { useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

import { toast } from "react-toastify";

import InputFeedback from "../components/InputFeedback";
import ImagePreviewModal from "../components/ImagePreviewModal";
import { TableHeader } from "components/organisms/TableHeader";

import { Maximize } from "lucide-react";

function PostDescOtable({
  post,
  pages,
  refetchPages,
  userSelected,
  tableId,
  filters,
}) {
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(null);
  /**
   * @param {string} pageNumber
   * @param {string} newValue
   */
  const handleDetailsChange = async (pageNumber, newValue) => {
    const pageDoc = await getDoc(
      doc(db, "posts", post?.id, "pages", pageNumber)
    );
    const postPage = pageDoc.data();

    if (postPage.text !== newValue) {
      await updateDoc(doc(db, "posts", post?.id, "pages", pageNumber), {
        text: newValue,
      })
        .then(() => {
          toast.success("Details saved successfully");
          refetchPages(post);
        })
        .catch((error) => {
          toast.error("Error saving details", error);
        });
    }
  };

  /**
   * @param {string} pageNumber
   * @param {string} newValue
   */
  const handleNotesChange = async (pageNumber, newValue) => {
    const pageDoc = await getDoc(
      doc(db, "posts", post?.id, "pages", pageNumber)
    );
    const postPage = pageDoc.data();

    if (postPage.notes !== newValue) {
      await updateDoc(doc(db, "posts", post?.id, "pages", pageNumber), {
        notes: newValue,
      })
        .then(() => {
          toast.success("Notes saved successfully");
        })
        .catch((error) => {
          toast.error("Error saving notes");
        });
    }
  };

  return (
    <div id={tableId} className="!mx-4 md:!mx-10">
      <div className="hidden lg:grid items-center align-middle grid-cols-8 gap-1 font-light bg-background py-1">
        <div className="col-span-8 md:col-span-1">Thumbnail</div>

        <div className="col-span-2 flex flex-col">
          <TableHeader headerType="text" />
        </div>

        <div className="col-span-2 flex flex-col">
          <TableHeader headerType="feedback" />
        </div>

        <div className="col-span-3 flex flex-col">
          <TableHeader headerType="notes" />
        </div>
      </div>

      {pages.map((page) => {
        return (
          <div
            key={page.id}
            className="mock-row grid grid-cols-8 gap-1 bg-white py-1 !border-b-[.5px] border-aux-gray "
          >
            <div className="col-span-8 lg:col-span-1 py-3 flex  align-middle items-center relative">
              <img
                src={page.imageURL}
                alt="thumbnail"
                className="lg:!w-24 lg:!h-24 md:!h-60 h-48 w-full cursor-pointer object-cover"
                onClick={() => setShowImagePreviewModal(page)}
              />
              <div
                onClick={() => setShowImagePreviewModal(page)}
                className="absolute bottom-5 cursor-pointer right-2 lg:hidden bg-white rounded-full p-2"
              >
                <Maximize size={18} color="#262626" strokeWidth={1.5} />
              </div>
            </div>

            <div className="col-span-8 lg:col-span-2 py-3">
              <div className="flex flex-col lg:hidden">
                <TableHeader headerType="text" isMobile={true} />
              </div>
              <div
                className="bg-[#F6F6F6] px-1 py-2 whitespace-pre-wrap"
                contentEditable
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                  handleDetailsChange(page.id, e.target.textContent)
                }
              >
                {page.text}
              </div>
            </div>

            <div className="col-span-8 lg:col-span-2 py-3">
              <div className="flex flex-col lg:hidden">
                <TableHeader headerType="feedback" isMobile={true} />
              </div>
              <div className="flex justify-center w-full px-3">
                <InputFeedback
                  post={post}
                  pageNumber={parseInt(page.page)}
                  userSelected={userSelected}
                  filters={filters}
                />
              </div>
            </div>

            <div className="col-span-8 lg:col-span-3 py-3 h-max lg:h-full">
              <div className="flex flex-col lg:hidden">
                <TableHeader headerType="notes" isMobile={true} />
              </div>
              <div
                className={`bg-[#F6F6F6] px-1 py-2 whitespace-pre-wrap h-full`}
                contentEditable
                suppressContentEditableWarning={true}
                onBlur={(e) => handleNotesChange(page.id, e.target.textContent)}
              >
                <div className="overflow-auto text-start inline-block whitespace-pre-wrap">
                  {page.notes}
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {showImagePreviewModal !== null && (
        <ImagePreviewModal
          post={post}
          page={showImagePreviewModal}
          show={showImagePreviewModal}
          setShow={setShowImagePreviewModal}
        />
      )}
    </div>
  );
}

export default PostDescOtable;
