// @ts-check
import { CustomButton } from "components/atoms/CustomButton";
import { Tabs } from "components/molecules/Tabs";
import moment from "moment";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
/**
 * @param {object} props
 * @param {string} props.description
 * @param {string} props.createdAt
 * @param {string} props.longDescription
 * @param {()=>void} props.onFilter
 * @param {()=>void} props.onExport
 * @param {()=>void} props.onShare
 * @param {(param : "Buyer Perspective"|"Seller Data Table")=>void} props.onTabChange
 * @returns
 */
export const PostDescriptionHeader = ({
  description,
  createdAt,
  longDescription,
  onFilter,
  onExport,
  onShare,
  onTabChange,
}) => {
  const tabItems = ["Buyer Perspective", "Seller Data Table"];
  const location = useLocation();

  const selectedTab = useMemo(() => {
    if (location.pathname.includes("/post-o-table")) {
      return tabItems[1];
    }
    return tabItems[0];
  }, [location.pathname]);

  return (
    <div className="md:!m-10 !m-4">
      <div className=" flex flex-col sm:flex-row justify-between !mb-3">
        <h2>{description}</h2>
        <p className="text-sm font-light">
          Uploaded on {moment(createdAt).format("MMM DD,YYYY")}
        </p>
      </div>
      <p className="!mb-5 italic text-base ">{longDescription}</p>
      <div className="flex flex-col justify-center lg:flex-row md:justify-between w-full items-center">
        <div className="grid gap-2  grid-cols-2 sm:grid-cols-3 !mb-4 lg:!mb-0 lg:flex lg:flex-row">
          <CustomButton
            onClick={onFilter}
            variant="secondary"
            className=" h-10 text-base"
          >
            Filter Data
          </CustomButton>
          <CustomButton onClick={onExport} variant="secondary" className="h-10">
            Export Data as Table
          </CustomButton>
          <CustomButton
            onClick={onShare}
            className="h-10 col-span-2 sm:col-span-1"
          >
            Share Capsule with Users
          </CustomButton>
        </div>
        <div className="ml-2">
          <Tabs
            onChange={onTabChange}
            selectedItem={selectedTab}
            items={tabItems}
          />
        </div>
      </div>
    </div>
  );
};
