// @ts-check
/**
 * @typedef {import("../../utils/types").Page} Page
 * @typedef {import("../../utils/types").Capsule} Capsule
 */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "firebaseConfig";

import { useAuth } from "context/AuthContext";
import OutgoingPreviewModal from "components/OutgoingPreviewModal";
import { PostDescriptionHeader } from "./PostDescriptionHeader";
import { CapsulePageCard } from "./CapsulePageCard";
import PostDescOtable from "pages/PostDescOtable";
import TableFilterModal from "components/TableFilterModal";
import useExportPdf from "hooks/useExportPdf";
import { ShareCapsuleModal } from "components/templates/ShareCapsuleModal";

function PostDescOgrid() {
  const [post, setPost] = useState(null);
  const [pages, setPages] = useState([]);

  const [showModal, setShowModal] = useState(null);
  const [showSharePostModal, setShowSharePostModal] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * @type {[string:"grid"|"table",(string:"grid"|"table")=>void]}
   */
  const [selectedView, setSelectedView] = useState();

  const [showFilterModal, setShowFilterModal] = useState(false);

  const [filters, setFilters] = useState({
    likes: true,
    buys: true,
    urgents: true,
    comments: true,
    onHolds: true,
  });

  const [userSelected, setUserSelected] = useState({
    id: "all",
    displayName: "All",
  });

  const pagesWithFeedback = useMemo(() => {
    const newPages = [];
    const allSelected = userSelected.id === "all";

    post?.likes
      ?.filter((like) => allSelected || like.id === userSelected.id)
      .map((like) => newPages.push(like.page));

    post?.buys
      ?.filter((buy) => allSelected || buy.id === userSelected.id)
      .map((buy) => newPages.push(buy.page));

    post?.comments
      ?.filter((comment) => allSelected || comment.id === userSelected.id)
      .map((comment) => newPages.push(comment.page));

    post?.onHold
      ?.filter((oh) => allSelected || oh.id === userSelected.id)
      .map((oh) => newPages.push(oh.page));

    // @ts-ignore
    return new Set(newPages);
  }, [post, userSelected]);

  const [showAllPages, setShowAllPages] = useState(true);

  const filteredPages = useMemo(() => {
    if (!pages) return [];
    return pages?.filter((page) =>
      !showAllPages ? pagesWithFeedback.has(parseInt(page.page)) : true
    );
  }, [pages, pagesWithFeedback, showAllPages]);

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname.includes("/post-o-table")) {
      setSelectedView("table");
    }
    if (location.pathname.includes("/post-o-grid")) {
      setSelectedView("grid");
    }
  }, [location]);
  const tableId = "mock-table-id";
  const { exportToPDF, loading: isExportLoading } = useExportPdf({
    post,
    filteredPages,
    tableId,
    filter: filters,
  });

  const { currentUser } = useAuth();

  const scrollRef = useRef(null);

  const getPages = async (post) => {
    await getDocs(
      query(collection(db, "posts", post?.id, "pages"), orderBy("page", "asc"))
      // @ts-ignore
    ).then((res) =>
      // @ts-ignore
      setPages(res.docs.map((docu) => ({ ...docu.data(), id: docu.id })))
    );
  };

  const getData = () => {
    dispatch({ type: "showLoading" });
    getDoc(doc(db, "posts", params.id))
      .then(async (response) => {
        const tempPost = { ...response.data(), id: response.id };
        setPost(tempPost);
        await getPages(tempPost);
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
      });
  };

  const getUsersSharedWith = () => {
    getDocs(
      query(
        collection(db, "sharedPosts"),
        where("post", "==", post.id),
        where("sharedBy", "==", currentUser?.uid)
      )
    ).then(async (response) => {
      const sharedPosts = response.docs.map((docu) => ({
        ...docu.data(),
        id: docu.id,
      }));

      await Promise.all(
        sharedPosts.map(async (sp) => {
          const sharedWith = await getDoc(doc(db, "users", sp.sharedWith));

          return {
            ...sp,
            sharedWith: sharedWith.data(),
          };
        })
      );
    });
  };

  const getUserData = async () => {
    dispatch({ type: "showLoading" });
    const querySnapshot = await getDocs(collection(db, "users"));
    const temp = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      temp.push({ ...doc.data(), id: doc.id });
    });
    //console.log(temp);
    dispatch({ type: "hideLoading" });
  };

  useEffect(() => {
    getData();
    getUserData();
  }, []);

  useEffect(() => {
    if (post) {
      getUsersSharedWith();
    }
  }, [post]);

  useEffect(() => {
    const handleScrollToY = async () => {
      if (scrollRef.current && location.state?.scrollY) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        scrollRef.current.scrollTo({
          top: location.state.scrollY,
          behavior: "smooth",
        });
      }
    };

    handleScrollToY();
  }, [location.state?.scrollY]);

  return (
    <>
      <PostDescriptionHeader
        description={post?.description}
        createdAt={post?.createdAt}
        longDescription={post?.longDescription}
        onFilter={() => {
          setShowFilterModal(true);
        }}
        onExport={exportToPDF}
        onShare={() => setShowSharePostModal(true)}
        onTabChange={(param) => {
          if (param === "Seller Data Table") {
            navigate(`/post-o-table/${post.id}`);
          }
          if (param === "Buyer Perspective") {
            navigate(`/post-o-grid/${post.id}`);
          }
        }}
      ></PostDescriptionHeader>

      {selectedView == "grid" && (
        <div className="grid  !mx-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 !gap-5">
          {filteredPages.map((page) => (
            <CapsulePageCard
              key={page.id}
              post={post}
              page={page}
              selectedUser={userSelected}
              filters={filters}
              setShowModal={() => setShowModal(page)}
            ></CapsulePageCard>
          ))}
        </div>
      )}
      {selectedView === "table" && (
        <PostDescOtable
          post={post}
          tableId={tableId}
          pages={filteredPages}
          refetchPages={getPages}
          userSelected={userSelected}
          filters={filters}
        />
      )}

      {showModal !== null && (
        <OutgoingPreviewModal
          post={post}
          page={showModal}
          show={showModal}
          setShow={setShowModal}
          getData={getData}
        />
      )}
      {showFilterModal && (
        <TableFilterModal
          show={showFilterModal}
          setShow={setShowFilterModal}
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          post={post}
          filters={filters}
          setFilters={setFilters}
          showAllPages={showAllPages}
          setShowAllPages={setShowAllPages}
        />
      )}
      <ShareCapsuleModal
        show={showSharePostModal}
        setShow={() => setShowSharePostModal(false)}
        capsuleId={post?.id}
        capsuleName={post?.description}
      />
    </>
  );
}

export default PostDescOgrid;
